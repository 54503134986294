import { KnownDataTypeEnum } from './known-data-types';
import { StepNameWithArguments } from './steps';

/**
 * Map of all known DFA-steps with their inputs and outputs
 */
export const KNOWN_DYNAMIC_FIELD_ACTION_STEPS = {
  // User
  userToFullNameString: {
    input: [KnownDataTypeEnum.CONTACT_BASIC],
    output: KnownDataTypeEnum.STRING,
  },
  userToPhoneNumberString: {
    input: [KnownDataTypeEnum.CONTACT],
    output: KnownDataTypeEnum.STRING,
  },
  userInfoToUser: {
    input: [KnownDataTypeEnum.CONTACT_BASIC],
    output: KnownDataTypeEnum.CONTACT,
  },
  // Asset
  assetIdToAsset: {
    input: [KnownDataTypeEnum.ASSET_ID],
    output: KnownDataTypeEnum.ASSET,
  },
  assetInfoToAsset: {
    input: [KnownDataTypeEnum.ASSET_INFO],
    output: KnownDataTypeEnum.ASSET,
  },
  assetToAssetTypeNameString: {
    input: [KnownDataTypeEnum.ASSET_INFO],
    output: KnownDataTypeEnum.STRING,
  },
  assetToCustomerAccountId: {
    input: [KnownDataTypeEnum.ASSET],
    output: KnownDataTypeEnum.STRING,
  },
  assetToLocationAddress: {
    input: [KnownDataTypeEnum.ASSET],
    output: KnownDataTypeEnum.ADDRESS,
  },
  assetToCustomPropertyValue: {
    input: [KnownDataTypeEnum.ASSET, KnownDataTypeEnum.STRING],
    output: KnownDataTypeEnum.ANY,
  },
  assetInfoToCustomerId: {
    input: [KnownDataTypeEnum.ASSET_INFO],
    output: KnownDataTypeEnum.ORGANIZATION_ID,
  },
  // Address
  addressToCityString: {
    input: [KnownDataTypeEnum.ADDRESS],
    output: KnownDataTypeEnum.STRING,
  },
  // Phone
  phoneNumberStringToPhoneNumberObject: {
    input: [KnownDataTypeEnum.STRING],
    output: KnownDataTypeEnum.PHONE_NUMBER,
  },
  // Account -> OrganizationRaw
  accountIdToAccount: {
    input: [KnownDataTypeEnum.ORGANIZATION_ID],
    output: KnownDataTypeEnum.ORGANIZATION,
  },
  accountToBillingAddressAddress: {
    input: [KnownDataTypeEnum.ORGANIZATION],
    output: KnownDataTypeEnum.ADDRESS,
  },
  accountInfoToCompanyName: {
    input: [KnownDataTypeEnum.ORGANIZATION_BASIC],
    output: KnownDataTypeEnum.STRING,
  },
  accountInfoToCustomerNumber: {
    input: [KnownDataTypeEnum.ORGANIZATION_BASIC],
    output: KnownDataTypeEnum.STRING,
  },
  accountToAccountInfo: {
    input: [KnownDataTypeEnum.ORGANIZATION],
    output: KnownDataTypeEnum.ORGANIZATION_BASIC,
  },
  accountInfoToAccount: {
    input: [KnownDataTypeEnum.ORGANIZATION_BASIC],
    output: KnownDataTypeEnum.ORGANIZATION,
  },
  accountToPhoneNumberString: {
    input: [KnownDataTypeEnum.ORGANIZATION],
    output: KnownDataTypeEnum.STRING,
  },

  // Utils
  logValue: {
    input: [KnownDataTypeEnum.ANY],
    output: KnownDataTypeEnum.ANY,
  },
  setValue: {
    input: [KnownDataTypeEnum.ANY, KnownDataTypeEnum.ANY],
    output: KnownDataTypeEnum.ANY,
  },
} as const;

export type KnownDynamicFieldActionSteps = typeof KNOWN_DYNAMIC_FIELD_ACTION_STEPS;
export type DynamicActionStepName = keyof KnownDynamicFieldActionSteps;
export type DynamicStepNameWithArguments = StepNameWithArguments<KnownDynamicFieldActionSteps>;
