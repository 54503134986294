import { ActivityFieldTypeEnum, ActivityObjectSchema } from '@remberg/activities/common/main';
import {
  WorkOrderActivityFieldTypeEnum,
  WorkOrderSpecificFieldTypeEnum,
} from './work-order-activity-field-type.enum';
import { WorkOrderActivityFieldEnum } from './work-order-activity-field.enum';

export const WORK_ORDER_ACTIVITY_OBJECT_SCHEMA = {
  [WorkOrderActivityFieldEnum.COUNTER]: ActivityFieldTypeEnum.NUMBER,
  [WorkOrderActivityFieldEnum.SUBJECT]: ActivityFieldTypeEnum.TEXT,
  [WorkOrderActivityFieldEnum.PARENT_WORK_ORDER]: ActivityFieldTypeEnum.WORK_ORDER,
  [WorkOrderActivityFieldEnum.DESCRIPTION]: ActivityFieldTypeEnum.HTML,
  [WorkOrderActivityFieldEnum.EXTERNAL_REFERENCE]: ActivityFieldTypeEnum.TEXT,
  [WorkOrderActivityFieldEnum.DUE_DATE]: ActivityFieldTypeEnum.REMBERG_DATE,
  [WorkOrderActivityFieldEnum.ESTIMATED_DURATION]: ActivityFieldTypeEnum.NUMBER,
  [WorkOrderActivityFieldEnum.RESPONSIBLE_CONTACT]: ActivityFieldTypeEnum.CONTACT,
  [WorkOrderActivityFieldEnum.RESPONSIBLE_GROUP]: ActivityFieldTypeEnum.USER_GROUP,
  [WorkOrderActivityFieldEnum.LOCATION]: ActivityFieldTypeEnum.ADDRESS,
  [WorkOrderActivityFieldEnum.RELATED_CASE]: ActivityFieldTypeEnum.CASE,
  [WorkOrderActivityFieldEnum.RELATED_TICKET]: ActivityFieldTypeEnum.TICKET2,
  [WorkOrderActivityFieldEnum.RELATED_ORGANIZATION]: ActivityFieldTypeEnum.ORGANIZATION,
  [WorkOrderActivityFieldEnum.RELATED_CONTACTS]: ActivityFieldTypeEnum.CONTACT_ARRAY,
  [WorkOrderActivityFieldEnum.ADDITIONAL_CONTACT_INFORMATION]: ActivityFieldTypeEnum.TEXT,
  [WorkOrderActivityFieldEnum.RELATED_ASSETS]: ActivityFieldTypeEnum.ASSET_ARRAY,
  [WorkOrderActivityFieldEnum.CUSTOM_PROPERTIES]: ActivityFieldTypeEnum.CUSTOM_PROPERTIES,
  [WorkOrderActivityFieldEnum.OVERDUE_NOTIFICATION_SENT]: ActivityFieldTypeEnum.BOOLEAN,
  [WorkOrderActivityFieldEnum.APPROACHING_DUE_DATE_NOTIFICATION_SENT]:
    ActivityFieldTypeEnum.BOOLEAN,
  [WorkOrderActivityFieldEnum.TOUCHED_BY_USER]: ActivityFieldTypeEnum.BOOLEAN,
  [WorkOrderActivityFieldEnum.PLANNING_START_DATE]: ActivityFieldTypeEnum.REMBERG_DATE_TIME,
  [WorkOrderActivityFieldEnum.PLANNING_END_DATE]: ActivityFieldTypeEnum.REMBERG_DATE_TIME,
  [WorkOrderActivityFieldEnum.PLANNING_IS_FULL_DAY]: ActivityFieldTypeEnum.BOOLEAN,
  [WorkOrderActivityFieldEnum.PLANNING_ASSIGNED_CONTACTS]: ActivityFieldTypeEnum.CONTACT_ARRAY,
  [WorkOrderActivityFieldEnum.PLANNING_ASSIGNED_GROUPS]: ActivityFieldTypeEnum.USER_GROUP_ARRAY,
  [WorkOrderActivityFieldEnum.STATUS]: ActivityFieldTypeEnum.WORK_ORDER_STATUS,
  [WorkOrderActivityFieldEnum.TYPE]: ActivityFieldTypeEnum.WORK_ORDER_TYPE,
  // Fields with WorkOrderSpecificFieldTypeEnum
  [WorkOrderActivityFieldEnum.PRIORITY]: WorkOrderSpecificFieldTypeEnum.PRIORITY,
  [WorkOrderActivityFieldEnum.CHECKLIST]: WorkOrderSpecificFieldTypeEnum.CHECKLIST,
} satisfies ActivityObjectSchema<WorkOrderActivityFieldEnum, WorkOrderActivityFieldTypeEnum>;
