export enum AssetActivityFieldEnum {
  ASSET_TYPE_ID = 'assetTypeId',
  PARENT_ID = 'parentId',
  ANCESTOR_IDS = 'ancestorId',
  SERIAL_NUMBER = 'serialNumber',
  NAME = 'name',
  STATUS = 'status',
  RELATED_ORGANIZATION_IDS = 'relatedOrganizationIds',
  TENANT_OWNER_CONTACT_ID = 'tenantOwnerContactId',
  RELATED_CONTACT_IDS = 'relatedContactIds',
  LOCATION = 'location',
  USER_GROUP_IDS = 'userGroupIds',
  CUSTOM_PROPERTY_VALUES = 'customPropertyValues',
}
