export enum MaintenancePlanPopulateEnum {
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  WORK_ORDER_TEMPLATE_STATUS = 'workOrderTemplateStatus',
  WORK_ORDER_TEMPLATE_TYPE = 'workOrderTemplateType',
  WORK_ORDER_TEMPLATE_RESPONSIBLE_CONTACT = 'workOrderTemplateResponsibleContact',
  WORK_ORDER_TEMPLATE_RESPONSIBLE_GROUP = 'workOrderTemplateResponsibleGroup',
  WORK_ORDER_TEMPLATE_ASSIGNED_CONTACTS = 'workOrderTemplateAssignedContacts',
  WORK_ORDER_TEMPLATE_ASSIGNED_GROUPS = 'workOrderTemplateAssignedGroups',
}
