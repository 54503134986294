import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IMAGE_FALLBACK_URL, MenuFunction } from '@remberg/global/ui';
import { NavigationService, ServerFilePaths } from '@remberg/ui-core/core';

@Component({
  selector: 'app-preview-card',
  templateUrl: './preview-card.component.html',
  styleUrls: ['./preview-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewCardComponent {
  /** Main title of the preview card [required]*/
  @Input() public title?: string;
  /** FileId to show as a thumbnail of the card */
  @Input() public thumbnailId?: string;
  @Input() public thumbnailSource?: ServerFilePaths;
  @Input() public thumbnailRounded?: boolean = false;
  /** Fallback to use in case the thumbnail could not be found. Use IMAGE_FALLBACK_URL set up in the app.config */
  @Input() public thumbnailFallback: string = IMAGE_FALLBACK_URL.user;
  /** Hides the thumbnail */
  @Input() public hideThumbnail = false;
  /** Text display below the title */
  @Input() public subtitle?: string;
  /** Action to show in the 3dot menu for each card */
  @Input() public menuFunctions?: MenuFunction<any>[];
  /** Data object represented by the component to pass on to the menu function once it is selected */
  @Input() public element: unknown;
  /** linkUrl: if set, the text will be marked as a link and a click will navigate to the linkUrl */
  @Input() public linkUrl?: string;
  /** alwaysOpenInNewTab: if set to true together with a linkPath, the link will always be opened in a new tab/window */
  @Input() public alwaysOpenInNewTab?: boolean;
  /** You can disable the click here */
  @Input() public isClickable?: boolean = true;
  /** Optional action to show on the right side of the card */
  @Input() public customAction?: MenuFunction<any>;
  /** Triggered when the card is clicked */
  @Output() public onClick = new EventEmitter<void>();

  protected readonly IMAGE_FALLBACK_URL = IMAGE_FALLBACK_URL;

  constructor(private navigationService: NavigationService) {}

  protected handleClick(evt: MouseEvent): void {
    if (this.linkUrl) {
      this.navigationService.navigateWithNewTabOption(evt, this.linkUrl, this.alwaysOpenInNewTab);
      evt.stopPropagation();
    }
    this.onClick.emit();
  }

  protected handleMouseup(evt: MouseEvent): void {
    if (this.linkUrl) {
      this.navigationService.navigateWithNewTabOption(evt, this.linkUrl, this.alwaysOpenInNewTab);
      evt.stopPropagation();
    }
  }

  protected isTooltipDisabled(element: HTMLElement): boolean {
    return element.offsetWidth >= element.scrollWidth;
  }
}
