<div class="card flex-row is-clickable" [ngClass]="{ 'is-clickable': isClickable }">
  <div
    class="flex-row-center full-width content-container"
    (click)="handleClick($event)"
    (mouseup)="handleMouseup($event)"
  >
    <div class="thumbnail" *ngIf="!hideThumbnail">
      <img
        *ngIf="thumbnailSource"
        class="thumbnail-image"
        [ngClass]="{ 'thumbnail-image-rounded': thumbnailRounded }"
        [src]="thumbnailId | fileUrl: thumbnailSource : 'small' | async"
        [src-fallback]="thumbnailFallback"
      />
      <img
        *ngIf="!thumbnailSource"
        class="thumbnail-image"
        [ngClass]="{ 'thumbnail-image-rounded': thumbnailRounded }"
        [src]="thumbnailFallback"
      />
    </div>

    <div
      class="flex-col content"
      [ngClass]="{
        'no-actions': !menuFunctions?.length,
        'content-thumbnail-rounded': thumbnailRounded,
      }"
    >
      <div
        class="mat-subtitle-2 full-width text-ellipsis"
        #titleDiv
        [matTooltip]="title ?? ''"
        [matTooltipDisabled]="isTooltipDisabled(titleDiv)"
      >
        <span>{{ title }}</span>
      </div>
      <div
        *ngIf="subtitle"
        class="mat-body-2 full-width text-ellipsis"
        #subtitleDiv
        [matTooltip]="subtitle"
        [matTooltipDisabled]="isTooltipDisabled(subtitleDiv)"
      >
        <span>{{ subtitle }}</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="(menuFunctions?.length ?? 0) > 0">
    <button class="menu-button" mat-icon-button [matMenuTriggerFor]="elementMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #elementMenu="matMenu" xPosition="before">
      <button
        *ngFor="let menuFunction of menuFunctions"
        attr.data-test-id="options-button-{{ menuFunction.dataTestId }}"
        mat-menu-item
        (click)="menuFunction.click(element)"
      >
        <mat-icon>{{ menuFunction?.icon }}</mat-icon>
        <span>{{ menuFunction?.name }}</span>
      </button>
    </mat-menu>
  </ng-container>
  <ng-container *ngIf="customAction">
    <button
      class="custom-action-button"
      mat-icon-button
      (click)="customAction.click(element)"
      [matTooltip]="customAction.name"
    >
      <mat-icon>{{ customAction.icon }}</mat-icon>
    </button>
  </ng-container>
</div>
