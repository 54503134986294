import { inject } from '@angular/core';
import { CanActivateFn, GuardResult, Route, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { QualifiedPermissionKey } from '../definitions/permission-checks';
import { GlobalSelectors } from '../store';

export const hasAccessGuard =
  (config: {
    featureFlags?: FeatureFlagEnum[];
    permissions?: QualifiedPermissionKey[];
    disablePermissions?: QualifiedPermissionKey[];
    isIonicDisabled?: boolean;
    isTenantOwnerRequired?: boolean;
  }): CanActivateFn =>
  async (): Promise<GuardResult> => {
    const store = inject(Store);
    const router = inject(Router);
    const logger = inject(LogService);

    const isTenantOwner = await firstValueFrom(store.select(GlobalSelectors.selectIsTenantOwner));
    const isIonic = await firstValueFrom(store.select(GlobalSelectors.selectIsIonic));

    const hasAccess = await firstValueFrom(
      store.select(
        GlobalSelectors.selectHasAccess(config.featureFlags ?? [], config.permissions ?? []),
      ),
    );

    const isAccessDisabled =
      config.disablePermissions &&
      (await firstValueFrom(
        store.select(GlobalSelectors.selectHasAccess([], config.disablePermissions ?? [])),
      ));

    const isMissingTenantOwnerAccess = !isTenantOwner && config.isTenantOwnerRequired;
    const isDisabledDueToIonic = isIonic && config.isIonicDisabled;
    if (!isDisabledDueToIonic && !isMissingTenantOwnerAccess && hasAccess && !isAccessDisabled)
      return true;

    logger.info()('HasAccess Guard: Redirecting from: ' + router.url);
    return router.parseUrl('/');
  };

type GuardedRouteAccessConfig = {
  path?: string;
  targetRoute: Omit<Route, 'path'>;
  featureFlags?: FeatureFlagEnum[];
  permissions?: QualifiedPermissionKey[];
  disablePermissions?: QualifiedPermissionKey[];
  isIonicDisabled?: boolean;
  isTenantOwnerRequired?: boolean;
};

export function generateGuardedRoute(config: GuardedRouteAccessConfig): Route {
  const { path, targetRoute, ...rest } = config;
  const guardFn = hasAccessGuard(rest);

  const route: Route = {
    ...targetRoute,
    path,
    canActivate: [guardFn],
    canActivateChild: [guardFn],
  };

  return route;
}
