import {
  DEFAULT_ISO_LANGUAGE_CODE,
  IsoLanguageCodesEnum,
  LanguageCodeEnum,
  MultiLanguageText,
} from '@remberg/global/common/core';

// helper to get text from json dependent on locale

export function getLocaleText(
  input: string | MultiLanguageText | undefined,
  locale: LanguageCodeEnum | IsoLanguageCodesEnum | string,
  defaultText?: string,
): string | undefined {
  if (typeof input === 'string') {
    return input;
  }

  const lang = getIsoLanguageCode(locale);

  if (typeof input === 'object' && input) {
    const validInput = Object.fromEntries(Object.entries(input).filter(([_, value]) => value));

    if (Object.prototype.hasOwnProperty.call(validInput, lang)) {
      return validInput[lang];
    }

    // In case of local development, pick English if it is specified (needed for running E2E tests locally)
    if (
      (locale as any) === 'code' &&
      Object.prototype.hasOwnProperty.call(validInput, DEFAULT_ISO_LANGUAGE_CODE)
    ) {
      return validInput[DEFAULT_ISO_LANGUAGE_CODE];
    }

    if (Object.prototype.hasOwnProperty.call(validInput, IsoLanguageCodesEnum.DE)) {
      return validInput[IsoLanguageCodesEnum.DE];
    }

    // Otherwise we just return the first value that is in the validInput locale object
    return Object.values(validInput)[0] as string;
  }

  if (defaultText) {
    return defaultText;
  }
  return undefined;
}

function getIsoLanguageCode(
  locale?: LanguageCodeEnum | IsoLanguageCodesEnum | string,
): IsoLanguageCodesEnum {
  const twoLetterCultureCode = (locale ?? '').split('-')[0];

  return twoLetterCultureCode &&
    Object.values(IsoLanguageCodesEnum).includes(twoLetterCultureCode as IsoLanguageCodesEnum)
    ? (twoLetterCultureCode as IsoLanguageCodesEnum)
    : // default pdf language
      DEFAULT_ISO_LANGUAGE_CODE;
}
