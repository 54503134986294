export enum TrackingEventEnum {
  // account
  ACCOUNT_SETTINGS_OVERVIEW_PAGE_VIEWED = 'accountSettingsOverviewPage_viewed',

  // ai copilot
  AI_COPILOT_CASE_REPLY_DRAFT_GENERATED = 'aiCopilot_caseReplyDraftGenerated',
  AI_COPILOT_CASE_REPLY_DRAFT_FAILED = 'aiCopilot_caseReplyDraftFailed',
  AI_COPILOT_EDITOR_PROMPT_SENT = 'aiCopilot_editorPromptSent',
  AI_COPILOT_CASE_SUMMARY_GENERATED = 'aiCopilot_caseSummaryGenerated',
  AI_COPILOT_ASK_AI_RATING = 'aiCopilot_askAiRating',
  AI_COPILOT_ASK_AI_QUESTION = 'aiCopilot_askAiQuestion',

  // assets
  ASSET_ASSET_CREATED = 'asset_assetCreated',
  ASSET_OVERVIEW_PAGE_VIEWED = 'assetOverviewPage_viewed',
  ASSET_DETAIL_PAGE_VIEWED = 'assetDetailPage_viewed',
  ASSET_DETAIL_PAGE_PUBLIC_VIEWED = 'assetDetailPagePublic_viewed',
  ASSET_DETAIL_PAGE_ADD_WORK_ORDER = 'buttonClicked_AssetDetailPage_AddWorkOrder',
  ASSET_DETAIL_PAGE_NOTES_SECTION_VIEWED = 'assetDetailPage_NotesSectionViewed',
  ASSET_DETAIL_PAGE_PARTS_SECTION_VIEWED = 'assetDetailPage_PartsSectionViewed',
  ASSET_DETAIL_PAGE_FILES_SECTION_VIEWED = 'assetDetailPage_FilesSectionViewed',
  ASSET_DETAIL_PAGE_CASES_SECTION_VIEWED = 'assetDetailPage_CasesSectionViewed',
  ASSET_DETAIL_PAGE_TASKS_SECTION_VIEWED = 'assetDetailPage_TasksSectionViewed',
  ASSET_DETAIL_PAGE_WO_SECTION_VIEWED = 'assetDetailPage_WOSectionViewed',
  ASSET_DETAIL_PAGE_WO2_SECTION_VIEWED = 'assetDetailPage_WO2SectionViewed',
  ASSET_DETAIL_PAGE_MP_SECTION_VIEWED = 'assetDetailPage_MPSectionViewed',
  ASSET_DETAIL_PAGE_FORMS_SECTION_VIEWED = 'assetDetailPage_FormsSectionViewed',
  ASSET_DETAIL_PAGE_TREE_SECTION_VIEWED = 'assetDetailPage_TreeSectionViewed',
  ASSET_DETAIL_PAGE_ACTIVITIES_SECTION_VIEWED = 'assetDetailPage_ActivitiesSectionViewed',

  // assets types
  ASSET_TYPE_CREATED = 'assetType_assetCreated',
  ASSET_TYPE_DETAIL_PAGE_VIEWED = 'assetTypeDetailPage_viewed',

  // cases
  CASE_ASSIGNED_USER_CHANGED = 'case_assignedUserChanged',
  CASE_CREATED = 'case_created',
  CASE_DELETED = 'case_deleted',
  CASE_DETAIL_PAGE_ADD_WORK_ORDER = 'buttonClicked_CaseDetailPage_AddWorkOrder',
  CASE_DETAIL_PAGE_PUBLIC_CREATION_VIEWED = 'caseDetailPagePublicCreation_viewed',
  CASE_DETAIL_PAGE_VIEWED = 'caseDetailPage_viewed',
  CASE_EMAIL_COMPOSED = 'case_emailComposed',
  CASE_EMAIL_TEMPLATE_CHANGED = 'case_EmailTemplateChanged',
  CASE_EMAIL_TEMPLATE_CREATED = 'case_EmailTemplateCreated',
  CASE_EMAIL_TEMPLATE_DELETED = 'case_EmailTemplateDeleted',
  CASE_EMAIL_TEMPLATE_USED = 'case_EmailTemplateUsed',
  CASE_MESSAGE_SENT = 'case_messageSent',
  CASE_NOTE_SENT = 'case_noteSent',
  CASE_NOTIFICATION_UPDATES_ACTIVATED = 'case_notificationUpdatesActivated',
  CASE_NOTIFICATION_UPDATES_DEACTIVATED = 'case_notificationUpdatesDeactivated',
  CASE_OVERVIEW_PAGE_VIEWED = 'caseOverviewPage_viewed',
  CASE_PARTS_ADDED = 'case_partsAdded',
  CASE_PARTS_EDITED = 'case_partsEdited',
  CASE_REQUEST_CONFIRMATION_PAGE_PUBLIC_VIEWED = 'caseRequestConfirmationPagePublic_viewed',
  CASE_STATUS_CHANGED = 'case_statusChanged',
  CASE_SUMMARY_CHANGED = 'case_summaryChanged',
  CASE_USER_MENTIONED = 'case_userMentioned',

  // contacts
  CONTACT_CREATED = 'contact_created',
  CONTACT_ACTIVATION_SENT = 'contact_activationSent',
  CONTACTS_OVERVIEW_PAGE_VIEWED = 'contactsOverviewPage_viewed',

  // dashboard
  DASHBOARD_OVERVIEW_PAGE_VIEWED = 'dashboardOverviewPage_viewed',

  // files
  FILE_OVERVIEW_PAGE_VIEWED = 'fileOverviewPage_viewed',

  // forms
  FORM_TEMPLATE_OVERVIEW_PAGE_VIEWED = 'formTemplateOverviewPage_viewed',
  FORM_INSTANCE_DELETED = 'formInstance_deleted',
  FORM_INSTANCE_CONTENT_CHANGED = 'formInstance_contentChanged',
  FORM_INSTANCE_DETAIL_PAGE_VIEWED = 'formInstanceDetailPage_viewed',
  FORM_INSTANCE_CREATED = 'formInstance_created',
  FORM_INSTANCE_CREATED_VIA_DUPLICATION = 'formInstance_createdViaDuplication',
  FORM_INSTANCE_STATUS_CHANGED = 'formInstance_statusChanged',
  FORM_INSTANCE_EMAIL_SENT = 'formInstance_emailSent',

  // maintenance plans
  MAINTENANCE_PLAN_OVERVIEW_PAGE_VIEWED = 'maintenancePlanOverviewPage_viewed',
  MAINTENANCE_PLAN_DETAIL_PAGE_VIEWED = 'maintenancePlanDetailPage_viewed',
  MAINTENANCE_PLAN_CREATED = 'maintenancePlan_created',
  MAINTENANCE_PLAN_DELETED = 'maintenancePlan_deleted',
  MAINTENANCE_PLAN_GENERATION_PERIOD_CHANGED = 'maintenancePlan_generationPeriodChanged',
  MAINTENANCE_PLAN_TERMINATED = 'maintenancePlan_terminated',
  MAINTENANCE_PLAN_WO_TEMPLATE_CHANGED = 'maintenancePlan_woTemplateChanged',
  MAINTENANCE_PLAN_ASSOCIATED_ASSETS_CHANGED = 'maintenancePlan_associatedAssetsChanged',
  MAINTENANCE_PLAN_ASSOCIATED_ORGANIZATION_CHANGED = 'maintenancePlan_associatedOrganizationChanged',

  // organizations
  ORGANIZATIONS_OVERVIEW_PAGE_VIEWED = 'organizationsOverviewPage_viewed',
  ORGANIZATIONS_DETAIL_PAGE_VIEWED = 'organizationsDetailPage_viewed',

  // parts
  PARTS_OVERVIEW_PAGE_VIEWED = 'partsOverviewPage_viewed',

  // scheduling board
  SCHEDULING_BOARD_OVERVIEW_PAGE_VIEWED = 'schedulingBoardOverviewPage_viewed',
  SCHEDULING_BOARD_OBJECT_CHANGED = 'schedulingBoard_objectChanged',
  SCHEDULING_BOARD_PERFORMING_PERSONS_SORTING_CHANGED = 'schedulingBoard_performingPersonsSortingChanged',
  SCHEDULING_BOARD_PERFORMING_PERSONS_SELECTION_CHANGED = 'schedulingBoard_performingPersonsSelectionChanged',

  SCHEDULING_BOARD_UNSCHEDULED_LIST_FILTER_APPLIED = 'schedulingBoard_unscheduledList_filterApplied',
  SCHEDULING_BOARD_SCHEDULER_FILTER_APPLIED = 'schedulingBoard_scheduler_filterApplied',
  SCHEDULING_BOARD_CONFIRMATION_MESSAGE_CONFIRMED = 'schedulingBoard_confirmationMessageConfirmed',
  SCHEDULING_BOARD_SCHEDULER_CUSTOMIZATION_SETTING_CHANGED = 'schedulingBoard_Scheduler_customizationSettingChanged',
  SCHEDULING_BOARD_UNSCHEDULED_LIST_CUSTOMIZATION_SETTING_CHANGED = 'schedulingBoard_UnscheduledList_customizationSettingChanged',

  SCHEDULING_BOARD_EVENT_TITLE_CLICKED = 'schedulingBoard_eventCardTitleClicked',
  SCHEDULING_BOARD_WORK_ORDER_TITLE_CLICKED = 'schedulingBoard_workOrderCardTitleClicked',
  SCHEDULING_BOARD_EVENT_EDIT_SIDEBAR_SAVE = 'buttonClicked_schedulingBoard_eventEditSidebarSave',
  SCHEDULING_BOARD_WORK_ORDER_EDIT_SIDEBAR_SAVE = 'buttonClicked_schedulingBoard_workOrderEditSidebarSave',
  SCHEDULING_BOARD_ADD_WORK_ORDER_CREATE = 'buttonClicked_SchedulingBoard_AddWorkOrderCreate',
  SCHEDULING_BOARD_ADD_EVENT_CREATE = 'buttonClicked_SchedulingBoard_AddEventCreate',
  SCHEDULING_BOARD_CLICK_AND_DRAG = 'clickAndDrag_SchedulingBoard',
  SCHEDULING_BOARD_COPY_CLICK_AND_DRAG = 'copyClickAndDrag_SchedulingBoard',

  // tasks
  TASK_ASSIGNED_USER_CHANGED = 'task_assignedUserChanged',
  TASK_COMPLETE_TASK = 'task_completeTask',
  TASK_CREATE_TASK = 'task_createTask',
  TASK_CREATED = 'task_created',
  TASK_DIALOG_VIEWED = 'taskDialog_viewed',
  TASK_OVERVIEW_PAGE_VIEWED = 'taskOverviewPage_viewed',
  TASK_STATUS_CHANGED = 'task_statusChanged',

  // tenant
  TENANT_ACCESS_REQUESTED = 'tenant_accessRequested',

  // user
  USER_LOGGED_IN = 'user_loggedIn',
  USER_LOGGED_OUT = 'user_loggedOut',
  USER_SUSPENDED = 'user_suspended',

  // work orders
  WORK_ORDER_OVERVIEW_PAGE_VIEWED = 'workOrderOverviewPage_viewed',
  WORK_ORDER_DETAIL_PAGE_VIEWED = 'workOrderDetailPage_viewed',
  WORK_ORDER_OVERVIEW_PAGE_ADD_BUTTON_CLICKED = 'buttonClicked_WorkOrderOverviewPage_Add',
  WORK_ORDER_VIEW_HIERARCHY_ON_SCHEDULING_BOARD_BUTTON_CLICKED = 'buttonClicked_ViewHierarchyOnSchedulingBoard',
  WORK_ORDER_CREATED = 'workOrder_created',
  WORK_ORDER_CHANGED = 'workOrder_changed',
  WORK_ORDER_DELETED = 'workOrder_deleted',

  // request intended
  FEATURE_ASSETS_REQUEST_INTENDED = 'featureAssets_requestIntended',
  FEATURE_ORGANIZATIONS_REQUEST_INTENDED = 'featureOrganizations_requestIntended',
  FEATURE_CASES_REQUEST_INTENDED = 'featureCases_requestIntended',
  FEATURE_APPOINTMENTS_REQUEST_INTENDED = 'featureAppointments_requestIntended',
  FEATURE_WORKORDERS_REQUEST_INTENDED = 'featureWorkOrders_requestIntended',
  FEATURE_DATA_EXPORT_REQUEST_INTENDED = 'featureDataExport_requestIntended',
  FEATURE_API_KEYS_REQUEST_INTENDED = 'featureApiKeys_requestIntended',

  // Redirects
  CENTER_REDIRECT = 'center_redirect',
  FORMS2_REDIRECT = 'forms2_redirect',
  CONTACT_PREVIEW_REDIRECT = 'contact_redirect',
  ASSETS_DETAIL_NEW_REDIRECT = 'assets_fromDetailNew_redirect',

  // Overview Pages
  VISUALIZATION_TYPE_LOADED = 'visualizationType_loaded',
  VISUALIZATION_TYPE_CHANGED = 'visualizationType_changed',
}
