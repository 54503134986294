import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SortDirectionEnum, SortTypeConfig, assertDefined } from '@remberg/global/common/core';
import {
  MANUAL_SORT_LABEL,
  SortField,
  SortingDialogData,
  SortingDialogResult,
} from '@remberg/global/ui';
import { isDefined } from 'class-validator';
import { filter, firstValueFrom } from 'rxjs';
import { getDomRectOfEventTarget, openDialogNextToTarget } from '../../../dialogs/dialogs';
import { GlobalSelectors, RootGlobalState } from '../../../store';
import { SortingDesktopDialogComponent } from '../desktop-dialog/sorting-desktop-dialog.component';
import { SortingMobileDialogComponent } from '../mobile-dialog/sorting-mobile-dialog.component';

@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortButtonComponent<T extends string> {
  @Input() public sortField?: T;
  @Input() public sortDirection?: SortDirectionEnum;
  @Input() public sortFields?: SortField<T>;
  @Input() public defaultSort?: Required<SortTypeConfig<T>>;
  @Input() public isManual?: boolean;

  protected readonly manualBadgeText = MANUAL_SORT_LABEL.charAt(0).toUpperCase();

  @Output() public sortChanged = new EventEmitter<SortTypeConfig<T>>();

  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly matDialog: MatDialog,
  ) {}

  protected async onSortClicked(currentTarget: EventTarget | null): Promise<void> {
    assertDefined(this.sortFields, 'SortFields are not defined');

    const isXSmallView = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsXSmallView),
    );
    const dialogData: SortingDialogData<T> = {
      currentSortDirection: this.sortDirection,
      currentSortField: this.sortField,
      sortFields: this.sortFields,
      defaultSort: this.defaultSort,
      isManual: this.isManual,
      targetDomRect: getDomRectOfEventTarget(currentTarget),
    };

    const dialogRef = isXSmallView
      ? this.openOnMobileView(dialogData)
      : await this.openOnDesktopView(dialogData);

    dialogRef
      .afterClosed()
      .pipe(filter(isDefined))
      .subscribe((result) => {
        if (
          result.isConfirmed &&
          // emit only if sorting params have changed
          (result.sorting.direction !== this.sortDirection ||
            result.sorting.field !== this.sortField ||
            this.isManual)
        ) {
          this.sortChanged.emit(result.sorting);
        }
      });
  }

  private openOnMobileView(
    data: SortingDialogData<T>,
  ): MatDialogRef<SortingMobileDialogComponent, SortingDialogResult<T>> {
    return this.matDialog.open<
      SortingMobileDialogComponent,
      SortingDialogData<T>,
      SortingDialogResult<T>
    >(SortingMobileDialogComponent, {
      panelClass: 'mobile-device-full-screen',
      disableClose: false,
      autoFocus: false,
      data,
    });
  }

  private async openOnDesktopView(
    data: SortingDialogData<T>,
  ): Promise<MatDialogRef<SortingDesktopDialogComponent, SortingDialogResult<T>>> {
    return await openDialogNextToTarget({
      data,
      targetDomRect: data.targetDomRect,
      matDialog: this.matDialog,
      component: SortingDesktopDialogComponent,
    });
  }
}
