export enum Tickets2FilterEnum {
  ID = '_id',

  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  CREATED_BY_TYPE = 'createdByType',
  CREATED_BY_EMAIL = 'createdByEmail',
  UPDATED_AT = 'updatedAt',
  UPDATE_BY = 'updateBy',

  PRIORITY = 'priority',
  STATUS = 'status',
  SUPPORT_EMAIL_ADDRESS = 'supportEmailAddress',

  IS_COMPLETED = 'isCompleted',
  FOLLOWER = 'followerIds',
  SUMMARY = 'summary',
  SOLUTION = 'solution',

  ASSIGNED_CONTACT = 'assigneeContact',
  ASSIGNED_USER_GROUP = 'assignedUserGroup',
  CATEGORY = 'category',

  RELATED_PART = 'relatedPart',
  RELATED_ASSET = 'relatedAsset',
  RELATED_ASSET_TYPE = 'relatedAssetType',
  RELATED_ORGANIZATION = 'relatedOrganization',
  RELATED_CONTACT = 'relatedContact',
  TICKETS2 = 'tickets2',
}
