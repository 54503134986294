import { Inject, Injectable } from '@angular/core';
import { ContactBasic } from '@remberg/crm/common/base';
import { CONTACTS_OFFLINE_SERVICE, ContactsOfflineServiceInterface } from '@remberg/crm/ui/clients';
import {
  PersonListItem,
  StepsProvider,
  Task,
  rembergDateTimeToRembergDate,
} from '@remberg/forms/common/main';
import { Address, formatToRembergNumberString } from '@remberg/global/common/core';
import { ServiceCase } from '@remberg/tickets/common/main';
import {
  SERVICE_CASE_OFFLINE_SERVICE,
  ServiceCaseOfflineServiceInterface,
} from '@remberg/tickets/ui/clients';
import { WorkOrderType } from '@remberg/work-orders/common/base';
import { WorkOrderChecklistItem, WorkOrderRaw } from '@remberg/work-orders/common/main';
import {
  WORK_ORDER_2_OFFLINE_SERVICE,
  WORK_ORDER_TYPE_2_OFFLINE_SERVICE,
  WorkOrder2OfflineServiceInterface,
  WorkOrderType2OfflineServiceInterface,
} from '@remberg/work-orders/ui/clients';
import { PrefillingStepFunctions } from './interfaces';

@Injectable()
export class WorkOrder2PrefillingStepsProvider implements StepsProvider<PrefillingStepFunctions> {
  constructor(
    @Inject(WORK_ORDER_2_OFFLINE_SERVICE)
    private readonly workOrderService: WorkOrder2OfflineServiceInterface,
    @Inject(SERVICE_CASE_OFFLINE_SERVICE)
    private readonly serviceCaseService: ServiceCaseOfflineServiceInterface,
    @Inject(CONTACTS_OFFLINE_SERVICE)
    private readonly contactService: ContactsOfflineServiceInterface,
    @Inject(WORK_ORDER_TYPE_2_OFFLINE_SERVICE)
    private readonly workOrderTypeService: WorkOrderType2OfflineServiceInterface,
  ) {}

  public getSteps(): Partial<PrefillingStepFunctions> {
    return {
      workOrderIdToWorkOrder: (id: string | undefined) => this.loadWorkOrder(id),
      workOrderToLocationAddress: (input: WorkOrderRaw | undefined): Address | undefined =>
        input?.location,
      workOrderToTasks: (input: WorkOrderRaw | undefined): Task[] | undefined =>
        input?.checklist?.map(toTask),
      workOrderToAssetIds: (input: WorkOrderRaw | undefined): string[] | undefined =>
        input?.relatedAssetIds,
      workOrderToTitleString: (input: WorkOrderRaw | undefined): string | undefined =>
        input?.subject,
      workOrderToDescriptionString: (input: WorkOrderRaw | undefined): string | undefined =>
        input?.description,
      workOrderToDueDate: (input: WorkOrderRaw | undefined) => input?.dueDate,
      workOrderToERPReferenceString: (input: WorkOrderRaw | undefined): string | undefined =>
        input?.externalReference,
      workOrderToContacts: (
        input: WorkOrderRaw | undefined,
      ): Promise<ContactBasic[]> | undefined =>
        input ? this.loadRelatedContacts(input.relatedContactIds) : undefined,
      contactsToPersonListEntries: (
        input: ContactBasic[] | undefined,
      ): PersonListItem[] | undefined => (input ? input.map(toPersonListItem) : undefined),
      workOrderToResponsibleUserId: (input: WorkOrderRaw | undefined): string | undefined =>
        input?.responsibleContactId,
      // TODO refactor once WO 1.0 is deprecated
      workOrderToPerformByUserId: (input: WorkOrderRaw | undefined): string | undefined =>
        undefined,
      // TODO remove after v.2.79 mobile release. Should be replaced by workOrderToTypeReferenceString
      workOrderToTypeNumberString: (input: WorkOrderRaw | undefined): Promise<string | undefined> =>
        this.loadWorkOrderType(input?.typeValue?.typeId).then((workOrderType) =>
          workOrderType?.counter ? String(workOrderType.counter) : undefined,
        ),
      workOrderToTypeReferenceString: (
        input: WorkOrderRaw | undefined,
      ): Promise<string | undefined> =>
        this.loadWorkOrderType(input?.typeValue?.typeId).then(
          (workOrderType) => workOrderType?.reference,
        ),
      workOrderToPriorityString: (input: WorkOrderRaw | undefined): string | undefined =>
        input?.priority,
      workOrderToStartDate: (input: WorkOrderRaw | undefined) =>
        input?.planning?.startDate
          ? rembergDateTimeToRembergDate(input.planning.startDate)
          : undefined,
      workOrderToEndDate: (input: WorkOrderRaw | undefined) =>
        input?.planning?.endDate ? rembergDateTimeToRembergDate(input.planning.endDate) : undefined,
      workOrderToAdditionalContactInformationString: (
        input: WorkOrderRaw | undefined,
      ): string | undefined => input?.additionalContactInformation,
      workOrderToOrganizationAccountId: (input: WorkOrderRaw | undefined): string | undefined =>
        input?.relatedOrganizationId,
      workOrderToCaseSubjectString: (
        input: WorkOrderRaw | undefined,
      ): Promise<string | undefined> =>
        this.loadRelatedCase(input?.relatedCaseId).then((relatedCase) => relatedCase?.subject),

      workOrderToCaseTicketAndSubjectString: async (
        input: WorkOrderRaw | undefined,
      ): Promise<string | undefined> => {
        const relatedCase = await this.loadRelatedCase(input?.relatedCaseId);
        if (!relatedCase) {
          return;
        }
        const { ticketID, subject } = relatedCase;
        return `[${formatToRembergNumberString(ticketID)}] ${subject}`;
      },
      workOrderToCustomPropertyValue: (
        wo: WorkOrderRaw | undefined,
        externalReference: string | undefined,
      ) =>
        externalReference
          ? wo?.customPropertyValues?.find((cp) => cp.externalReference === externalReference)
          : undefined,
      workOrderToWorkOrderCounter: (input: WorkOrderRaw | undefined): string | undefined =>
        input?.counter ? formatToRembergNumberString(input.counter, true) : undefined,
    };
  }

  private async loadWorkOrder(id: string | undefined): Promise<WorkOrderRaw | undefined> {
    return id ? await this.workOrderService.tryGetInstance(id) : undefined;
  }

  private async loadRelatedCase(ticketId: string | undefined): Promise<ServiceCase | undefined> {
    return ticketId ? await this.serviceCaseService.tryGetInstance(ticketId) : undefined;
  }

  private async loadWorkOrderType(id: string | undefined): Promise<WorkOrderType | undefined> {
    return id ? await this.workOrderTypeService.tryGetInstance(id) : undefined;
  }

  private async loadRelatedContacts(contactIds: string[] | undefined): Promise<ContactBasic[]> {
    if (!contactIds) {
      return [];
    }
    return await this.contactService.getManyBasicByIds(contactIds);
  }
}

// TODO refactor once WO 1.0 is deprecated
function toTask(workOrderChecklistItem: WorkOrderChecklistItem): Task {
  return {
    title: workOrderChecklistItem.title,
    comment: workOrderChecklistItem.description,
    done: workOrderChecklistItem.isCompleted,
    highPriority: workOrderChecklistItem.isHighPriority,
    necessary: !workOrderChecklistItem.isOptional,
  };
}

function toPersonListItem(contact: ContactBasic): PersonListItem {
  return {
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.primaryEmail,
  };
}
