import { AssetBasic } from '@remberg/assets/common/base';
import { ProductCompatibility } from '../definitions';

export function productToAssetBasic(product: ProductCompatibility): AssetBasic;
export function productToAssetBasic(
  product: ProductCompatibility | undefined,
): AssetBasic | undefined {
  return product
    ? {
        _id: getStringID(product),
        serialNumber: product.serialNumber,
        assetTypeId: getStringID(product.productType),
        assetTypeName: product.productTypeName,
        assetTypeImageId: product.productType?.image,
        customerId: product.customerOrganization && getStringID(product.customerOrganization),
        imageId: product.imageId,
      }
    : undefined;
}

function getStringID(obj: string | { _id?: string; id?: string }): string {
  if (typeof obj === 'string') {
    return obj; // Return obj if it's already a string
  } else {
    // Return _id if available, otherwise return id
    return obj._id || obj.id || '';
  }
}
