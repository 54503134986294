import { ActivityFieldTypeEnum, ActivityObjectSchema } from '@remberg/activities/common/main';
import {
  Ticket2ActivityFieldTypeEnum,
  Ticket2SpecificFieldTypeEnum,
} from './ticket2-activity-field-type.enum';
import { Ticket2ActivityFieldEnum } from './ticket2-activity-field.enum';

export const TICKET2_ACTIVITY_OBJECT_SCHEMA = {
  [Ticket2ActivityFieldEnum.SUBJECT]: ActivityFieldTypeEnum.TEXT,
  [Ticket2ActivityFieldEnum.ASSIGNED_CONTACT]: ActivityFieldTypeEnum.CONTACT,
  [Ticket2ActivityFieldEnum.ASSIGNED_USER_GROUP]: ActivityFieldTypeEnum.USER_GROUP,
  [Ticket2ActivityFieldEnum.SOLUTION]: ActivityFieldTypeEnum.TEXT,
  [Ticket2ActivityFieldEnum.CUSTOM_PROPERTY_VALUES]: ActivityFieldTypeEnum.CUSTOM_PROPERTIES,
  [Ticket2ActivityFieldEnum.RELATED_ORGANIZATIONS]: ActivityFieldTypeEnum.ORGANIZATION_ARRAY,
  [Ticket2ActivityFieldEnum.PORTAL_ACCESS_ORGANIZATIONS]: ActivityFieldTypeEnum.ORGANIZATION_ARRAY,
  [Ticket2ActivityFieldEnum.CONTACTS]: ActivityFieldTypeEnum.CONTACT_ARRAY,
  [Ticket2ActivityFieldEnum.FOLLOWERS]: ActivityFieldTypeEnum.CONTACT_ARRAY,
  [Ticket2ActivityFieldEnum.CATEGORY]: ActivityFieldTypeEnum.TICKET2_CATEGORY,
  [Ticket2ActivityFieldEnum.TICKETS2]: ActivityFieldTypeEnum.TICKET2_ARRAY,
  [Ticket2ActivityFieldEnum.IS_MOVE_PREVENTED]: ActivityFieldTypeEnum.BOOLEAN,
  [Ticket2ActivityFieldEnum.IS_MOVE_UNDO_PREVENTED]: ActivityFieldTypeEnum.BOOLEAN,
  [Ticket2ActivityFieldEnum.COUNTER]: ActivityFieldTypeEnum.NUMBER,
  [Ticket2ActivityFieldEnum.SUPPORT_EMAIL_ADDRESS]: ActivityFieldTypeEnum.TEXT,
  // Fields with Ticket2SpecificFieldTypeEnum
  [Ticket2ActivityFieldEnum.STATUS]: Ticket2SpecificFieldTypeEnum.STATUS,
  [Ticket2ActivityFieldEnum.PRIORITY]: Ticket2SpecificFieldTypeEnum.PRIORITY,
  [Ticket2ActivityFieldEnum.SUMMARY]: Ticket2SpecificFieldTypeEnum.SUMMARY,
  [Ticket2ActivityFieldEnum.RELATED_ASSETS]: ActivityFieldTypeEnum.ASSET_ARRAY,
  [Ticket2ActivityFieldEnum.AI_KNOWLEDGE_BASE_STATUS]:
    Ticket2SpecificFieldTypeEnum.AI_KNOWLEDGE_BASE_STATUS,
} satisfies ActivityObjectSchema<Ticket2ActivityFieldEnum, Ticket2ActivityFieldTypeEnum>;
