import { AssetBasic } from '@remberg/assets/common/base';
import { isUnknownObject, UnknownObject } from '@remberg/global/common/core';
import { ProductCompatibility } from '../../compatibility';
import { AssetPublic } from '../asset-public.interface';
import { Asset } from '../asset.interface';

type DefinedAsset = Asset | AssetBasic | AssetPublic | ProductCompatibility;

export type NamedAsset = DefinedAsset | UnknownObject | undefined;

export function getAssetName(
  asset: UnknownObject | undefined,
  shouldHideSerialNumber?: boolean,
): undefined;
export function getAssetName(asset: DefinedAsset, shouldHideSerialNumber?: boolean): string;
export function getAssetName(
  asset: NamedAsset,
  shouldHideSerialNumber?: boolean,
): string | undefined;
export function getAssetName(
  asset: NamedAsset,
  shouldHideSerialNumber?: boolean,
): string | undefined {
  if (typeof asset !== 'object' || isUnknownObject(asset)) {
    return undefined;
  }

  let assetName = '';
  if (asset.name?.length) {
    assetName = asset.name;
  } else if ('assetTypeLabel' in asset) {
    assetName = asset.assetTypeLabel;
  } else if ('productTypeName' in asset) {
    assetName = asset.productTypeName;
  } else if ('assetTypeName' in asset) {
    assetName = asset.assetTypeName;
  }

  return shouldHideSerialNumber ? assetName : `${assetName} (${asset.serialNumber})`;
}
