import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppointmentsPermissionsEnum } from '@remberg/appointments/common/main';
import { AssetsPermissionEnum } from '@remberg/assets/common/main';
import { CONTACT_QUERY_PARAM } from '@remberg/crm/common/main';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectionStatusEnum,
  ConnectivityServiceInterface,
  LogService,
  MaintenancePlanRightsEnum,
  UnreachableCaseError,
  WorkOrderRightsEnum,
} from '@remberg/global/ui';
import {
  Notification,
  NotificationQueue,
  NotificationQueueMessage,
  NotificationTargetTypeEnum,
  NotificationTypeEnum,
} from '@remberg/notifications/common/main';
import { TasksPermissionEnum } from '@remberg/tasks/common/main';
import { TicketsPermissionEnum } from '@remberg/tickets2/common/main';
import { REMBERG_USER_QUERY_PARAM } from '@remberg/users/common/main';
import { WorkOrderPermissionsEnum } from '@remberg/work-orders/common/main';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { GlobalSelectors, RootGlobalState } from '../../store';
import { ServerConfigurationService } from '../server-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly notificationUrl = `${API_URL_PLACEHOLDER}/notifications`;

  // Exceptional API Url handling: event stream is not using http interceptor
  private readonly sseNotificationUrl$ = new ReplaySubject<string>();

  private readonly notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<
    Notification[]
  >([]);
  private ended: boolean = false;
  private eventSource?: EventSourcePolyfill;

  constructor(
    private http: HttpClient,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private logger: LogService,
    private serverConfigurationService: ServerConfigurationService,
    private readonly store: Store<RootGlobalState>,
  ) {
    this.serverConfigurationService.apiUrl
      .pipe(map((apiUrl) => apiUrl + '/notifications/sse'))
      .subscribe(this.sseNotificationUrl$);
  }

  // ============================== SERVER SIDE EVENTS ========================
  public setUpNotificationStream(): Observable<Notification[]> {
    let lastConnectSuccessful = true;
    let currentTimeout = 5000; // 5 sec
    const currentTimeoutMultiple = 2;

    this.ended = false;
    const stream = this.sseNotificationUrl$.pipe(
      take(1),
      withLatestFrom(this.store.select(GlobalSelectors.selectOutgoingRequestHeadersBypassSW)),
      mergeMap(
        ([sseNotificationUrl, headers]) =>
          new Observable<NotificationQueueMessage>((observer) => {
            const tokenHeader = {
              ...headers,
              Connection: 'keep-alive',
              'Content-Type': 'text/event-stream',
              'Cache-Control': 'no-cache',
            };
            this.eventSource = new EventSourcePolyfill(sseNotificationUrl, {
              headers: tokenHeader,
            });

            this.eventSource.onopen = (event: any) => {
              this.logger.info()('SSE open ');
              this.logger.debug()(event);
            };

            this.eventSource.onmessage = (event: any) => {
              const parsedData = JSON.parse(event.data);
              this.logger.debug()('SSE message received ');
              this.logger.debug()(parsedData);
              if (parsedData.new) {
                if (parsedData.new.length > 0) {
                  observer.next({ type: 'new', notifications: parsedData.new });
                }
              } else if (parsedData.read) {
                if (parsedData.read.length > 0) {
                  observer.next({ type: 'read', notifications: parsedData.read });
                }
              } else if (parsedData.removed) {
                observer.next({
                  type: 'removed',
                  notifications: [],
                  notificationIds: parsedData.removed.notificationIds,
                  shouldDeleteAll: parsedData.removed.shouldDeleteAll,
                });
              } else {
                observer.next({ type: 'queue', notifications: parsedData });
              }
            };

            this.eventSource.onerror = (error: any) => {
              if (lastConnectSuccessful) {
                this.logger.debug()('SSE error received ');
                this.logger.error()(error);
                this.logger.error()(JSON.stringify(error));
              }
              lastConnectSuccessful = false;
              this.eventSource?.close();
              observer.error(error);
            };
          }),
      ),
    );

    // recursive structure to reestablish the connection after a disconnect
    const setupSSE = (callback: any) => {
      const setupSSEErrorHandler = () => {
        // try reconnect after 5s
        if (!this.ended) {
          setTimeout(() => {
            callback(setupSSE);
            currentTimeout = currentTimeout * currentTimeoutMultiple;
            this.logger.debug()('Setting SSE timeout to: ' + currentTimeout);
          }, currentTimeout);
        }
      };
      if (this.connectivityService.getCurrentStatus() === ConnectionStatusEnum.Online) {
        // update notifications
        stream
          .pipe(
            withLatestFrom(
              this.store.select(
                GlobalSelectors.selectHasPermission(AssetsPermissionEnum.ASSETS_ENABLED, true),
              ),
              this.store.select(
                GlobalSelectors.selectHasPermission(TasksPermissionEnum.TASKS_ENABLED),
              ),
              this.store.select(
                GlobalSelectors.selectHasPermission(
                  AppointmentsPermissionsEnum.APPOINTMENTS_ENABLED,
                  true,
                ),
              ),
              this.store.select(
                GlobalSelectors.selectHasPermission(WorkOrderRightsEnum.WORK_ORDER_ENABLED),
              ),
              this.store.select(
                GlobalSelectors.selectHasPermission(WorkOrderPermissionsEnum.WORK_ORDERS_ENABLED),
              ),
              this.store.select(
                GlobalSelectors.selectHasPermission(TicketsPermissionEnum.TICKETS_ENABLED),
              ),
              this.store.select(
                GlobalSelectors.selectHasPermission(
                  MaintenancePlanRightsEnum.MAINTENANCE_PLAN_ENABLED,
                ),
              ),
            ),
          )
          .subscribe(
            ([
              data,
              hasPermissionAssetsEnabled,
              hasPermissionTasksEnabled,
              hasPermissionAppointmentsEnabled,
              hasPermissionWorkOrdersEnabled,
              hasPermissionWorkOrders2Enabled,
              hasPermissionTicketsEnabled,
              hasPermissionMaintenancePlanEnabled,
            ]) => {
              // Remove from current notifications
              if (
                data.type === 'removed' &&
                (data.notificationIds?.length || data.shouldDeleteAll)
              ) {
                if (data.shouldDeleteAll) {
                  this.notifications.next([]);
                } else {
                  const updatedNotifications = this.notifications
                    .getValue()
                    .filter(
                      (currentNotification) =>
                        !data.notificationIds?.includes(currentNotification._id ?? ''),
                    );
                  this.notifications.next(updatedNotifications);
                }
              } else if (
                data.type === 'read' &&
                data.notifications.length > 0 &&
                this.notifications.value.length > 0
              ) {
                const result = this.notifications.getValue();
                for (const incomingNotification of data.notifications) {
                  for (const currentNotification of result) {
                    if (currentNotification._id === incomingNotification._id) {
                      currentNotification.isRead = true;
                      break;
                    }
                  }
                }
                this.notifications.next(result);
                // Add to current notifications
              } else if (data.type === 'new' || data.type === 'queue') {
                let result: Notification[];
                if (data.type === 'queue') {
                  // restart with an empty queue
                  result = [];
                } else {
                  result = this.notifications.getValue();
                }
                for (const notification of data.notifications) {
                  const newNotification = {} as Notification;
                  newNotification._id = notification['_id'];
                  newNotification.deleted = notification['deleted'] ?? undefined;
                  newNotification.isRead = notification['isRead'] ?? false;
                  newNotification.message = notification['message'] ?? '';
                  newNotification.headline = notification['headline'] ?? '';
                  newNotification.notificationType = notification['notificationType'] ?? null;
                  newNotification.target = notification['target'] ?? '';
                  newNotification.targetType = notification['targetType'];
                  newNotification.payload = notification['payload'] ?? '';
                  newNotification.targetId = notification['targetId'] ?? '';
                  newNotification.targetDescriptor = notification['targetDescriptor'] ?? '';
                  newNotification.notifyingContact = notification['notifyingContact'];
                  newNotification.targetSubId = notification['targetSubId'] ?? '';
                  newNotification.asset = notification['asset'] ?? '';
                  newNotification.targetContactIds = notification['targetContactIds'] ?? [];
                  newNotification.timeStamp = notification['timeStamp']
                    ? new Date(notification['timeStamp'])
                    : new Date();

                  // filter out notifications that the user is missing a feature
                  if (
                    (hasPermissionAssetsEnabled &&
                      (notification.notificationType === NotificationTypeEnum.AssetAssignPerson ||
                        notification.notificationType === NotificationTypeEnum.AssetUpdate ||
                        notification.notificationType === NotificationTypeEnum.AssetMention)) ||
                    (hasPermissionTasksEnabled &&
                      notification.notificationType === NotificationTypeEnum.TaskOverdue) ||
                    notification.notificationType === NotificationTypeEnum.TaskAssignment ||
                    notification.notificationType === NotificationTypeEnum.TaskUpdate ||
                    (hasPermissionAppointmentsEnabled &&
                      (notification.notificationType ===
                        NotificationTypeEnum.AppointmentAssignment ||
                        notification.notificationType ===
                          NotificationTypeEnum.AppointmentUpdate)) ||
                    (hasPermissionWorkOrders2Enabled &&
                      (notification.notificationType ===
                        NotificationTypeEnum.WorkOrder2Assignment ||
                        notification.notificationType === NotificationTypeEnum.WorkOrder2Mention ||
                        notification.notificationType ===
                          NotificationTypeEnum.WorkOrder2DueDateApproaching ||
                        notification.notificationType === NotificationTypeEnum.WorkOrder2Update ||
                        notification.notificationType === NotificationTypeEnum.WorkOrder2OverDue ||
                        notification.notificationType ===
                          NotificationTypeEnum.WorkOrder2PlanningUpdate)) ||
                    (hasPermissionTicketsEnabled &&
                      (notification.notificationType === NotificationTypeEnum.TicketChange ||
                        notification.notificationType === NotificationTypeEnum.TicketCreated ||
                        notification.notificationType === NotificationTypeEnum.TicketMention ||
                        notification.notificationType === NotificationTypeEnum.TicketAssignment ||
                        notification.targetType === NotificationTargetTypeEnum.Case)) ||
                    // Tickets2
                    (hasPermissionTicketsEnabled &&
                      (notification.notificationType === NotificationTypeEnum.Ticket2Change ||
                        notification.notificationType === NotificationTypeEnum.Ticket2Created ||
                        notification.notificationType === NotificationTypeEnum.Ticket2Mention ||
                        notification.notificationType === NotificationTypeEnum.Ticket2Assignment ||
                        notification.targetType === NotificationTargetTypeEnum.Case)) ||
                    //Tickets2 end
                    notification.notificationType === NotificationTypeEnum.AccountRequestAccess ||
                    notification.notificationType === NotificationTypeEnum.ExportReady ||
                    notification.notificationType === NotificationTypeEnum.FormInstanceMention ||
                    (hasPermissionMaintenancePlanEnabled &&
                      notification.notificationType === NotificationTypeEnum.MaintenancePlanMention)
                  ) {
                    result.push(newNotification);
                  }
                }
                this.notifications.next(result);
              }
            },
            (error) => {
              setupSSEErrorHandler();
            },
          );
      } else {
        setupSSEErrorHandler();
      }
    };

    setupSSE(setupSSE);

    return this.notifications;
  }

  // ==================== CANCEL NOTIFICATION STREAM ==================
  public cancelNotificationStream(): void {
    if (this.eventSource) {
      this.logger.info()('Closing SSE connection...');
      this.ended = true;
      this.eventSource.close();
    }
  }

  // ==================== GET ALL NOTIFICATIONS ======================
  public getNotifications(): Observable<Notification[]> {
    return this.notifications;
  }

  // ==================== MARK NOTIFICATION(S) AS READ ==============
  // TODO S1-1644: Rename this path once there is a breaking mobile release, that sets the minimum mobile version to v2.46.0 or later
  public readNotificationsByTarget(target: string): Observable<NotificationQueue> {
    return this.http
      .post<ApiResponse<NotificationQueue>>(`${this.notificationUrl}/read/target/${target}`, {})
      .pipe(map((res) => res.data));
  }

  // TODO S1-1644: Rename this path once there is a breaking mobile release, that sets the minimum mobile version to v2.46.0 or later
  public readNotification(notification: string): Observable<NotificationQueue> {
    return this.http
      .post<ApiResponse<NotificationQueue>>(`${this.notificationUrl}/read/${notification}`, {})
      .pipe(map((res) => res.data));
  }

  // ==================== REMOVE NOTIFICATION(S) ==============
  public removeNotifications({
    notificationIds,
    shouldDeleteAll,
  }: {
    notificationIds?: string[];
    shouldDeleteAll?: boolean;
  }): Observable<NotificationQueue> {
    return this.http
      .delete<
        ApiResponse<NotificationQueue>
      >(`${this.notificationUrl}/remove`, { body: { notificationIds, shouldDeleteAll } })
      .pipe(map((res) => res.data));
  }

  // ============================== SETTINGS ========================
  public getNotificationSettings(): Observable<NotificationQueue> {
    const params = new HttpParams();
    return this.http
      .get<ApiResponse<NotificationQueue>>(this.notificationUrl + '/settings')
      .pipe(map((res) => res.data));
  }

  public saveNotificationSettings(notQueue: NotificationQueue): Observable<NotificationQueue> {
    const params = new HttpParams();
    // queue.settings['specific'] = JSON.stringify(Array.from(
    //   queue.settings.specific as Map<NotificationTypeEnum, NotificationSettingsEnum[]>));
    return this.http
      .put<ApiResponse<NotificationQueue>>(this.notificationUrl + '/settings', notQueue)
      .pipe(map((res) => res.data));
  }

  public getNotificationIcon(notification: Notification): { icon: string; isSvgIcon?: boolean } {
    switch (notification.notificationType) {
      case NotificationTypeEnum.AccountRequestAccess:
        return { icon: 'settings' };
      case NotificationTypeEnum.AssetAssignPerson:
      case NotificationTypeEnum.AssetUpdate:
      case NotificationTypeEnum.AssetMention:
        return { icon: 'asset', isSvgIcon: true };
      case NotificationTypeEnum.TaskAssignment:
      case NotificationTypeEnum.TaskOverdue:
      case NotificationTypeEnum.TaskUpdate:
        return { icon: 'check_circle' };
      case NotificationTypeEnum.TicketChange:
      case NotificationTypeEnum.TicketCreated:
      case NotificationTypeEnum.TicketMention:
      case NotificationTypeEnum.TicketAssignment:
      case NotificationTypeEnum.Ticket2Change:
      case NotificationTypeEnum.Ticket2Created:
      case NotificationTypeEnum.Ticket2Mention:
      case NotificationTypeEnum.Ticket2Assignment:
        return { icon: 'forum' };
      case NotificationTypeEnum.ExportReady:
        return { icon: 'cloud_download' };
      case NotificationTypeEnum.WorkOrder2OverDue:
      case NotificationTypeEnum.WorkOrder2Mention:
      case NotificationTypeEnum.WorkOrder2Assignment:
      case NotificationTypeEnum.WorkOrder2Update:
      case NotificationTypeEnum.WorkOrder2DueDateApproaching:
        return { icon: 'handyman' };
      case NotificationTypeEnum.MaintenancePlanMention:
      case NotificationTypeEnum.AppointmentAssignment:
      case NotificationTypeEnum.AppointmentUpdate:
      case NotificationTypeEnum.WorkOrder2PlanningUpdate:
        return { icon: 'event' };
      case NotificationTypeEnum.EmailFailed:
        if (notification.targetType === NotificationTargetTypeEnum.Case) {
          return { icon: 'forum' };
        } else {
          return { icon: 'email' };
        }
      default:
        return { icon: 'shop_basket' };
    }
  }

  public getNotificationLink(notification: Notification): string {
    switch (notification.notificationType) {
      case NotificationTypeEnum.TicketChange:
      case NotificationTypeEnum.TicketCreated:
      case NotificationTypeEnum.TicketMention:
      case NotificationTypeEnum.TicketAssignment:
        return '/servicecases/detail/' + notification.target;
      case NotificationTypeEnum.Ticket2Change:
      case NotificationTypeEnum.Ticket2Created:
      case NotificationTypeEnum.Ticket2Mention:
      case NotificationTypeEnum.Ticket2Assignment:
        return '/tickets2/detail/' + notification.target;
      case NotificationTypeEnum.TaskOverdue:
      case NotificationTypeEnum.TaskAssignment:
      case NotificationTypeEnum.TaskUpdate:
        return '/tasks/' + notification.target;
      case NotificationTypeEnum.AssetAssignPerson:
      case NotificationTypeEnum.AssetUpdate:
      case NotificationTypeEnum.AssetMention:
        return '/assets/detail/' + notification.target;
      case NotificationTypeEnum.AccountRequestAccess:
        return notification.payload?.isContact ? '/contacts' : '/settings/users';
      case NotificationTypeEnum.ExportReady:
        return '/settings/data-export';
      case NotificationTypeEnum.WorkOrder2Assignment:
      case NotificationTypeEnum.WorkOrder2DueDateApproaching:
      case NotificationTypeEnum.WorkOrder2Mention:
      case NotificationTypeEnum.WorkOrder2OverDue:
      case NotificationTypeEnum.WorkOrder2PlanningUpdate:
      case NotificationTypeEnum.WorkOrder2Update:
        return '/workorders2/detail/' + notification.target;
      case NotificationTypeEnum.AppointmentAssignment:
      case NotificationTypeEnum.AppointmentUpdate:
        return '/workorders2/scheduling/';
      case NotificationTypeEnum.FormInstanceMention:
        return '/forms/' + notification.targetSubId + '/detail/' + notification.targetId;
      case NotificationTypeEnum.MaintenancePlanMention:
        return '/workorders/plans/detail/' + notification.target;
      case NotificationTypeEnum.EmailFailed:
        return notification.target ?? '';
      default:
        throw new UnreachableCaseError(notification.notificationType);
    }
  }

  public getNotificationQueryParams(
    notification: Notification,
  ): Record<string, string> | undefined {
    switch (notification.notificationType) {
      case NotificationTypeEnum.AccountRequestAccess: {
        const queryParam = notification.payload?.isContact
          ? CONTACT_QUERY_PARAM
          : REMBERG_USER_QUERY_PARAM;
        return { [queryParam]: notification.target ?? '' };
      }
      default:
        return;
    }
  }

  public showNotificationOnIonic(notification: Notification): boolean {
    return (
      notification.notificationType === NotificationTypeEnum.WorkOrder2Assignment ||
      notification.notificationType === NotificationTypeEnum.WorkOrder2Update ||
      notification.notificationType === NotificationTypeEnum.WorkOrder2OverDue ||
      notification.notificationType === NotificationTypeEnum.WorkOrder2DueDateApproaching ||
      notification.notificationType === NotificationTypeEnum.WorkOrder2Mention ||
      notification.notificationType === NotificationTypeEnum.FormInstanceMention ||
      notification.notificationType === NotificationTypeEnum.TaskUpdate ||
      notification.notificationType === NotificationTypeEnum.TaskOverdue ||
      notification.notificationType === NotificationTypeEnum.TaskAssignment
    );
  }
}
