import { ContactsOrganizationTypeFilterEnum } from '@remberg/crm/common/main';
import { TimeZoneEnum } from '@remberg/global/common/core';
import {
  FormFieldTypesEnum,
  FormSectionTypesEnum,
  FormTemplateConfig,
  OrganizationSingleSelectPrefillTargetsEnum,
  StaticMultiSelectPrefillTargetsEnum,
  StaticSingleSelectPrefillTargetsEnum,
} from '../models';

export const EXAMPLE_FORM_TEMPLATE_CONFIG: FormTemplateConfig = {
  general: {
    timezone: TimeZoneEnum['Europe/Berlin'],
    hideInstanceCounterInPdf: false,
    pdfTitle: {
      en: 'PDF title',
      de: 'PDF-Titel',
      tr: 'PDF title [TR]',
      fr: 'PDF title [FR]',
      es: 'PDF title [ES]',
      it: 'PDF title [IT]',
    },
  },
  sections: [
    {
      id: 'exampleFieldSection',
      type: FormSectionTypesEnum.FIELD_SECTION,
      config: {
        headline: {
          text: {
            en: 'Example Field Section',
            de: 'Beispiel Feld Sektion',
            tr: 'Example Field Section [TR]',
            fr: 'Example Field Section [FR]',
            es: 'Example Field Section [ES]',
            it: 'Example Field Section [IT]',
          },
        },
      },
      fields: [
        {
          id: 'exampleSingleLineInput_1',
          type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
          config: {
            required: true,
            label: {
              text: {
                en: 'Single Line Input 1',
                de: 'Einzeiliger Input 1',
                tr: 'Single Line Input 1 [TR]',
                fr: 'Single Line Input 1 [FR]',
                es: 'Single Line Input 1 [ES]',
                it: 'Single Line Input 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              uiHide: false,
            },
            placeHolderText: {
              en: 'Example Placeholder',
              de: 'Platzhalter',
              tr: 'Example Placeholder [TR]',
              fr: 'Example Placeholder [FR]',
              es: 'Example Placeholder [ES]',
              it: 'Example Placeholder [IT]',
            },
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              validators: {
                emailAddress: false,
                maxCharacters: 10,
                minCharacters: 3,
                regex: {
                  pattern: '^[A-Z]*$',
                  errorText: {
                    en: 'Only uppercase letters allowed',
                    de: 'Only uppercase letters allowed [DE]',
                    tr: 'Only uppercase letters allowed [TR]',
                    fr: 'Only uppercase letters allowed [FR]',
                    es: 'Only uppercase letters allowed [ES]',
                    it: 'Only uppercase letters allowed [IT]',
                  },
                },
              },
            },
            prefill: {
              value: [
                {
                  input: 'assetId',
                  steps: ['assetIdToAsset', 'assetToAssetTypeNameString'],
                },
              ],
            },
          },
        },
        {
          id: 'exampleSingleLineInput_2',
          type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
          config: {
            required: false,
            label: {
              text: {
                en: 'Single Line Input 2',
                de: 'Einzeiliger Input 2',
                tr: 'Single Line Input 2 [TR]',
                fr: 'Single Line Input 2 [FR]',
                es: 'Single Line Input 2 [ES]',
                it: 'Single Line Input 2 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              uiHide: false,
            },
            placeHolderText: {
              en: 'Example Placeholder',
              de: 'Platzhalter',
              tr: 'Example Placeholder [TR]',
              fr: 'Example Placeholder [FR]',
              es: 'Example Placeholder [ES]',
              it: 'Example Placeholder [IT]',
            },
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
          },
        },
        {
          id: 'exampleStaticSingleSelect_1',
          type: FormFieldTypesEnum.STATIC_SINGLE_SELECT,
          config: {
            required: true,
            label: {
              text: {
                en: 'Static Single Select 1',
                de: 'Static Single Select 1 [DE]',
                tr: 'Static Single Select 1 [TR]',
                fr: 'Static Single Select 1 [FR]',
                es: 'Static Single Select 1 [ES]',
                it: 'Static Single Select 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              uiHide: false,
            },
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              options: {
                exampleOptionOne: {
                  en: 'Example option 1',
                  de: 'Example option 1 [DE]',
                  tr: 'Example option 1 [TR]',
                  fr: 'Example option 1 [FR]',
                  es: 'Example option 1 [ES]',
                  it: 'Example option 1 [IT]',
                },
                exampleOptionTwo: {
                  en: 'Example option 2',
                  de: 'Example option 2 [DE]',
                  tr: 'Example option 2 [TR]',
                  fr: 'Example option 2 [FR]',
                  es: 'Example option 2 [ES]',
                  it: 'Example option 2 [IT]',
                },
              },
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            prefill: {
              [StaticSingleSelectPrefillTargetsEnum.VALUE]: [
                {
                  input: 'none',
                  steps: [['staticString', 'exampleOptionOne']],
                },
              ],
            },
          },
        },
        {
          id: 'exampleStaticMultiSelect_1',
          type: FormFieldTypesEnum.STATIC_MULTI_SELECT,
          config: {
            label: {
              text: {
                en: 'Static Multi Select 1',
                de: 'Static Multi Select 1 [DE]',
                tr: 'Static Multi Select 1 [TR]',
                fr: 'Static Multi Select 1 [FR]',
                es: 'Static Multi Select 1 [ES]',
                it: 'Static Multi Select 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              uiHide: false,
            },
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            minAmountOfSelections: 2,
            maxAmountOfSelections: 3,
            value: {
              options: {
                exampleOptionOne: {
                  en: 'Example option 1',
                  de: 'Example option 1 [DE]',
                  tr: 'Example option 1 [TR]',
                  fr: 'Example option 1 [FR]',
                  es: 'Example option 1 [ES]',
                  it: 'Example option 1 [IT]',
                },
                exampleOptionTwo: {
                  en: 'Example option 2',
                  de: 'Example option 2 [DE]',
                  tr: 'Example option 2 [TR]',
                  fr: 'Example option 2 [FR]',
                  es: 'Example option 2 [ES]',
                  it: 'Example option 2 [IT]',
                },
                exampleOptionThree: {
                  en: 'Example option 3',
                  de: 'Example option 3 [DE]',
                  tr: 'Example option 3 [TR]',
                  fr: 'Example option 3 [FR]',
                  es: 'Example option 3 [ES]',
                  it: 'Example option 3 [IT]',
                },
              },
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              pdfAddLineBreaks: true,
            },
            prefill: {
              [StaticMultiSelectPrefillTargetsEnum.VALUE]: [
                {
                  input: 'none',
                  steps: [['staticStringArray', ['exampleOptionOne', 'exampleOptionTwo']]],
                },
              ],
            },
          },
        },
        {
          id: 'exampleCompanySingleSelect_1',
          type: FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT,
          config: {
            label: {
              text: {
                en: 'Company Single Select 1',
                de: 'Company Single Select 1 [DE]',
                tr: 'Company Single Select 1 [TR]',
                fr: 'Company Single Select 1 [FR]',
                es: 'Company Single Select 1 [ES]',
                it: 'Company Single Select 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              uiHide: false,
            },
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              disableCreation: false,
              pdfPrintCompanyNumber: false,
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            prefill: {
              [OrganizationSingleSelectPrefillTargetsEnum.SELECTED_ORGANIZATION]: [
                {
                  input: 'assetId',
                  steps: ['assetIdToAsset', 'assetToCustomerAccountId'],
                },
                {
                  input: 'currentAccountId',
                  steps: [],
                },
              ],
            },
            onChange: [
              {
                steps: ['accountInfoToCompanyName'],
                target: { id: 'disabledSingleLineInput_1' },
              },
              {
                steps: ['accountInfoToCustomerNumber'],
                target: { id: 'disabledSingleLineInput_2' },
              },
              {
                steps: ['accountInfoToAccount', 'accountToBillingAddressAddress'],
                target: { id: 'exampleAddressInput_1' },
              },
              {
                steps: ['accountInfoToAccount', 'accountToPhoneNumberString'],
                target: { id: 'exampleSingleLineInput_2' },
              },
              {
                steps: [
                  'accountInfoToAccount',
                  'accountToPhoneNumberString',
                  'phoneNumberStringToPhoneNumberObject',
                ],
                target: { id: 'examplePhoneNumberInput_1' },
              },
            ],
          },
        },
        {
          id: 'disabledSingleLineInput_1',
          type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
          config: {
            label: {
              text: {
                en: 'Selected company name',
                de: 'Selected company name [DE]',
                tr: 'Selected company name [TR]',
                fr: 'Selected company name [FR]',
                es: 'Selected company name [ES]',
                it: 'Selected company name [IT]',
              },
            },
            disabled: true,
          },
        },
        {
          id: 'disabledSingleLineInput_2',
          type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
          config: {
            label: {
              text: {
                en: 'Selected customer nr',
                de: 'Selected customer nr [DE]',
                tr: 'Selected customer nr [TR]',
                fr: 'Selected customer nr [FR]',
                es: 'Selected customer nr [ES]',
                it: 'Selected customer nr [IT]',
              },
            },
            disabled: true,
          },
        },
        {
          id: 'exampleAssetSingleSelect_1',
          type: FormFieldTypesEnum.ASSET_SINGLE_SELECT,
          config: {
            required: true,
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              disableAssetFormInstanceRelationship: false,
              disableCreation: false,
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            label: {
              text: {
                en: 'Asset Select 1',
                de: 'Asset Auswahl 1',
                tr: 'Asset Select 1 [TR]',
                fr: 'Asset Select 1 [FR]',
                es: 'Asset Select 1 [ES]',
                it: 'Asset Select 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              uiHide: false,
            },
            prefill: {
              selectedAsset: [
                {
                  input: 'assetId',
                  steps: [],
                },
              ],
            },
            onChange: [
              {
                steps: [
                  'assetInfoToAsset',
                  ['assetToCustomPropertyValue', 'cp-reference-5'], // cp-reference-5 - reference of the customer property that exists in your system
                ],
                target: { id: 'exampleMultiLineTextInput_1' },
              },
              {
                steps: ['assetInfoToCustomerId', 'accountIdToAccount', 'accountToAccountInfo'],
                target: { id: 'exampleCompanySingleSelect_1' },
              },
            ],
          },
        },
        {
          id: 'exampleAssetMultiSelect_1',
          type: FormFieldTypesEnum.ASSET_MULTI_SELECT,
          config: {
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              disableCreation: false,
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            label: {
              text: {
                en: 'Asset Multi Select 1',
                de: 'Asset Multi Auswahl 1',
                tr: 'Asset Multi Select 1 [TR]',
                fr: 'Asset Multi Select 1 [FR]',
                es: 'Asset Multi Select 1 [ES]',
                it: 'Asset Multi Select 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
          },
        },
        {
          id: 'exampleMultiLineTextInput_1',
          type: FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT,
          config: {
            required: true,
            label: {
              text: {
                en: 'MultiLine Input 1',
                de: 'Mehrzeiliger Input 1',
                tr: 'MultiLine Input 1 [TR]',
                fr: 'MultiLine Input 1 [FR]',
                es: 'MultiLine Input 1 [ES]',
                it: 'MultiLine Input 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              uiHide: false,
            },
            placeHolderText: {
              en: 'Example Placeholder',
              de: 'Platzhalter',
              tr: 'Example Placeholder [TR]',
              fr: 'Example Placeholder [FR]',
              es: 'Example Placeholder [ES]',
              it: 'Example Placeholder [IT]',
            },
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              validators: {
                maxCharacters: 1000,
                minCharacters: 3,
              },
              uiMaxRows: 5,
              uiMinRows: 2,
            },
            prefill: {
              value: [
                {
                  input: 'none',
                  steps: [['staticString', 'Default \n Multi \n Line \n Text']],
                },
              ],
            },
          },
        },
        {
          id: 'exampleUserSingleSelect_1',
          type: FormFieldTypesEnum.CONTACT_SINGLE_SELECT,
          config: {
            required: true,
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              pdfPrintEmailAddress: true,
            },
            label: {
              text: {
                en: 'User Select 1',
                de: 'User Auswahl 1',
                tr: 'User Select 1 [TR]',
                fr: 'User Select 1 [FR]',
                es: 'User Select 1 [ES]',
                it: 'User Select 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              uiHide: false,
            },
            prefill: {
              value: [
                {
                  input: 'currentUserId',
                  steps: [],
                },
              ],
            },
            onChange: [
              {
                steps: ['userToFullNameString'],
                target: { id: 'exampleSignatureSection', propertyName: 'name' },
              },
              {
                steps: [
                  'userInfoToUser',
                  'userToPhoneNumberString',
                  'phoneNumberStringToPhoneNumberObject',
                ],
                target: { id: 'examplePhoneNumberInput_1' },
              },
            ],
          },
        },
        {
          id: 'exampleUserSingleSelect_2',
          type: FormFieldTypesEnum.CONTACT_SINGLE_SELECT,
          config: {
            required: false,
            disabled: true,
            label: {
              text: {
                en: 'Assigned user',
                de: 'Assigned user [DE]',
                tr: 'Assigned user [TR]',
                fr: 'Assigned user [FR]',
                es: 'Assigned user [ES]',
                it: 'Assigned user [IT]',
              },
            },
            prefill: {
              value: [
                {
                  input: 'assignedUserId',
                  steps: [],
                },
              ],
            },
          },
        },
        {
          id: 'exampleSparePartsList_1',
          type: FormFieldTypesEnum.PART_LIST_INPUT,
          config: {
            requiredAmountOfEntries: 3,
            label: {
              text: {
                en: 'Part List 1',
                de: 'Ersatzteilliste 1',
                tr: 'Part List 1 [TR]',
                fr: 'Part List 1 [FR]',
                es: 'Part List 1 [ES]',
                it: 'Part List 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            fields: {
              partNumberSingleLineTextInput: {
                required: true,
                pdfHide: false,
                placeHolderText: {
                  en: 'Part Number Placeholder Text',
                  de: 'Part Number Placeholder Text',
                  tr: 'Part Number Placeholder Text',
                  fr: 'Part Number Placeholder Text',
                  es: 'Part Number Placeholder Text',
                  it: 'Part Number Placeholder Text',
                },
              },
              quantityNumericInput: {
                required: true,
                pdfHide: false,
              },
              descriptionMultiLineTextInput: {
                required: true,
                pdfHide: false,
                placeHolderText: {
                  en: 'Description Placeholder Text',
                  de: 'Description Placeholder Text',
                  tr: 'Description Placeholder Text',
                  fr: 'Description Placeholder Text',
                  es: 'Description Placeholder Text',
                  it: 'Description Placeholder Text',
                },
              },
              deliverToStaticSingleSelect: {
                required: true,
                pdfHide: false,
              },
              invoiceToStaticSingleSelect: {
                required: true,
                pdfHide: false,
              },
              warrantyBooleanInput: {
                pdfHide: false,
              },
            },
          },
        },
        {
          id: 'exampleSparePartsList_2',
          type: FormFieldTypesEnum.SPARE_PART_LIST_INPUT,
          config: {
            requiredAmountOfEntries: 0,
            label: {
              text: {
                en: 'Spare Part List 1',
                de: 'Ersatzteilliste 1',
                tr: 'Spare Part List 1 [TR]',
                fr: 'Spare Part List 1 [FR]',
                es: 'Spare Part List 1 [ES]',
                it: 'Spare Part List 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            fields: {
              partInput: {
                serialNumberPdfHide: false,
                namePdfHide: false,
                serialNumberRequired: true,
                nameRequired: true,
              },
              quantityNumericInput: {
                required: true,
                pdfHide: false,
              },
              descriptionMultiLineTextInput: {
                required: true,
                pdfHide: false,
                placeHolderText: {
                  en: 'Description Placeholder Text',
                  de: 'Description Placeholder Text',
                  tr: 'Description Placeholder Text',
                  fr: 'Description Placeholder Text',
                  es: 'Description Placeholder Text',
                  it: 'Description Placeholder Text',
                },
              },
              deliverToStaticSingleSelect: {
                required: true,
                pdfHide: false,
              },
              invoiceToStaticSingleSelect: {
                required: true,
                pdfHide: false,
              },
              warrantyBooleanInput: {
                pdfHide: false,
              },
            },
          },
        },
        {
          id: 'examplePersonList_1',
          type: FormFieldTypesEnum.PERSON_LIST_INPUT,
          config: {
            requiredAmountOfEntries: 1,
            label: {
              text: {
                en: 'Person List 1',
                de: 'Person Ersatzteilliste 1',
                tr: 'Person List 1 [TR]',
                fr: 'Person List 1 [FR]',
                es: 'Person List 1 [ES]',
                it: 'Person List 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            value: {
              organizationTypeFilter: ContactsOrganizationTypeFilterEnum.INTERNAL,
              firstNameRequired: true,
              lastNameRequired: true,
              email: {
                required: true,
                pdfHide: false,
                hide: false,
              },
            },
          },
        },
        {
          id: 'exampleBooleanInput_1',
          type: FormFieldTypesEnum.BOOLEAN_INPUT,
          config: {
            required: true,
            disabled: false,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            pdfWidth: 1,
            label: {
              text: {
                en: 'Boolean input 1',
                de: 'Boolean input 1 [DE]',
                tr: 'Boolean input 1 [TR]',
                fr: 'Boolean input 1 [FR]',
                es: 'Boolean input 1 [ES]',
                it: 'Boolean input 1 [IT]',
              },
            },
            prefill: {
              value: [
                {
                  input: 'none',
                  steps: [['staticBoolean', true]],
                },
              ],
            },
          },
        },
        {
          id: 'exampleAddressInput_1',
          type: FormFieldTypesEnum.ADDRESS_INPUT,
          config: {
            required: {
              street: false,
              streetNumber: false,
              supplement: false,
              zipPostCode: false,
              city: false,
              state: false,
              country: false,
            },
            label: {
              text: {
                en: 'Address Input 1',
                de: 'Anschrift Input 1',
                tr: 'Address Input 1 [TR]',
                fr: 'Address Input 1 [FR]',
                es: 'Address Input 1 [ES]',
                it: 'Address Input 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            value: {
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfAddLineBreaks: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            disabled: false,
            pdfHide: false,
            pdfWidth: 1,
            pdfHideIfValueIsEmpty: false,
            prefill: {
              value: [
                {
                  input: 'none',
                  steps: [
                    [
                      'staticAddress',
                      {
                        city: 'Default City',
                        street: 'Default Street',
                        streetNumber: '1',
                        country: 'Default Country',
                        other: 'Default supplement',
                        countryProvince: 'Default state',
                        company: 'Default Company',
                      },
                    ],
                  ],
                },
              ],
            },
            onChange: [
              {
                steps: ['addressToCityString'],
                target: { id: 'exampleSignatureSection', propertyName: 'location' },
              },
            ],
          },
        },
        {
          id: 'exampleAddressInput_2',
          type: FormFieldTypesEnum.ADDRESS_INPUT,
          config: {
            required: {
              street: false,
              streetNumber: false,
              supplement: false,
              zipPostCode: false,
              city: false,
              state: false,
              country: false,
            },
            label: {
              text: {
                en: 'Address Input 2',
                de: 'Anschrift Input 2',
                tr: 'Address Input 2 [TR]',
                fr: 'Address Input 2 [FR]',
                es: 'Address Input 2 [ES]',
                it: 'Address Input 2 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            value: {
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfAddLineBreaks: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            disabled: false,
            pdfHide: false,
            pdfWidth: 1,
            pdfHideIfValueIsEmpty: false,
            prefill: {
              value: [
                {
                  input: 'workOrderId',
                  steps: ['workOrderIdToWorkOrder', 'workOrderToLocationAddress'],
                },
              ],
            },
          },
        },
        {
          id: 'exampleExpenseListInput_1',
          type: FormFieldTypesEnum.EXPENSE_LIST_INPUT,
          config: {
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            requiredAmountOfEntries: 2,
            label: {
              text: {
                en: 'Expense List',
                de: 'Expense List [DE]',
                it: 'Expense List [IT]',
                tr: 'Expense List [TR]',
                es: 'Expense List [ES]',
                fr: 'Expense List [FR]',
              },
            },
            fields: {
              dateInput: {
                pdfHide: false,
                disabled: false,
                required: true,
              },
              commentMultiLineTextInput: {
                pdfHide: false,
                disabled: false,
                required: true,
              },
              expenseAmountNumericInput: {
                pdfHide: false,
                disabled: false,
                required: true,
              },
              expenseTypeSingleSelectInput: {
                pdfHide: false,
                disabled: false,
                required: true,
                values: {
                  exampleSelect_1: {
                    en: 'Example select 1',
                    de: 'Example select 1 [DE]',
                    tr: 'Example select 1 [TR]',
                    fr: 'Example select 1 [FR]',
                    es: 'Example select 1 [ES]',
                    it: 'Example select 1 [IT]',
                  },
                  exampleSelect_2: {
                    en: 'Example select 2',
                    de: 'Example select 2 [DE]',
                    tr: 'Example select 2 [TR]',
                    fr: 'Example select 2 [FR]',
                    es: 'Example select 2 [ES]',
                    it: 'Example select 2 [IT]',
                  },
                },
              },
            },
          },
        },
        {
          id: 'exampleTaskListInput_1',
          type: FormFieldTypesEnum.TASK_LIST_INPUT,
          config: {
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            requiredAmountOfEntries: 2,
            label: {
              text: {
                en: 'Check List',
                de: 'Check List [DE]',
                it: 'Check List [IT]',
                tr: 'Check List [TR]',
                es: 'Check List [ES]',
                fr: 'Check List [FR]',
              },
            },
            fields: {
              checkboxBooleanInput: {
                checkedByDefault: false,
                pdfHide: false,
              },
              titleSingleLineTextInput: {
                required: false,
                pdfHide: false,
              },
              commentMultiLineTextInput: {
                disabled: false,
                required: false,
                pdfHide: false,
              },
              highPrioBooleanInput: {
                disabled: false,
                pdfHide: false,
              },
              notNecessaryBooleanInput: {
                disabled: false,
                pdfHide: false,
              },
            },
            prefill: {
              entries: [
                {
                  input: 'none',
                  steps: [
                    [
                      'staticTasks',
                      [
                        {
                          done: false,
                          title: 'Item 1',
                          comment: 'This item was prefilled 1',
                          highPriority: false,
                          necessary: false,
                        },
                        {
                          done: false,
                          title: 'Item 2',
                          comment: 'This item was prefilled 2',
                          highPriority: true,
                          necessary: true,
                        },
                        {
                          done: true,
                          title: 'Item 3',
                          comment: 'This item was prefilled 3',
                          highPriority: false,
                          necessary: true,
                        },
                        {
                          done: false,
                          title: 'Item 4',
                          comment: 'This item was prefilled 4',
                          highPriority: true,
                          necessary: false,
                        },
                      ],
                    ],
                  ],
                },
              ],
            },
          },
        },
        {
          id: 'exampleTaskListInput_2',
          type: FormFieldTypesEnum.TASK_LIST_INPUT,
          config: {
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            requiredAmountOfEntries: 2,
            label: {
              text: {
                en: 'Check List 2',
                de: 'Check List 2 [DE]',
                it: 'Check List 2 [IT]',
                tr: 'Check List 2 [TR]',
                es: 'Check List 2 [ES]',
                fr: 'Check List 2 [FR]',
              },
            },
            fields: {
              checkboxBooleanInput: {
                checkedByDefault: false,
                pdfHide: false,
              },
              titleSingleLineTextInput: {
                required: false,
                pdfHide: false,
              },
              commentMultiLineTextInput: {
                disabled: false,
                required: false,
                pdfHide: false,
              },
              highPrioBooleanInput: {
                disabled: false,
                pdfHide: false,
              },
              notNecessaryBooleanInput: {
                disabled: false,
                pdfHide: false,
              },
            },
            prefill: {
              entries: [
                {
                  input: 'workOrderId',
                  steps: ['workOrderIdToWorkOrder', 'workOrderToTasks'],
                },
              ],
            },
          },
        },
        {
          id: 'richTextInput_1',
          type: FormFieldTypesEnum.RICH_TEXT_INPUT,
          config: {
            required: true,
            placeHolderText: {
              en: 'Rich Text Placeholder Text EN',
              de: 'Rich Text Placeholder Text DE',
              tr: 'Rich Text Placeholder Text TR',
              fr: 'Rich Text Placeholder Text FR',
              es: 'Rich Text Placeholder Text ES',
              it: 'Rich Text Placeholder Text IT',
            },
            label: {
              text: {
                en: 'Rich Text Input 1',
                de: 'Rich Text Eingabe 1',
                tr: 'Rich Text Input 1 [TR]',
                fr: 'Rich Text Input 1 [FR]',
                es: 'Rich Text Input 1 [ES]',
                it: 'Rich Text Input 1 [IT]',
              },
            },
            value: {
              validators: {
                minCharacters: 10,
                maxCharacters: 200,
              },
            },
            prefill: {
              value: [
                {
                  input: 'none',
                  steps: [['staticString', '<h1>Default rich text content</h1>']],
                },
              ],
            },
          },
        },
        {
          id: 'exampleHtmlDisplay_1',
          type: FormFieldTypesEnum.HTML_DISPLAY,
          config: {
            text: {
              en: '<h1>HTML Display 1</h1><p>This field is in: <b>EN</b></p><p><i>This should be an italic text</i></p>',
              de: '<h1>HTML Display 1</h1><p>This field is in: <b>DE</b></p><p><i>This should be an italic text</i></p>',
              tr: '<h1>HTML Display 1</h1><p>This field is in: <b>TR</b></p><p><i>This should be an italic text</i></p>',
              fr: '<h1>HTML Display 1</h1><p>This field is in: <b>FR</b></p><p><i>This should be an italic text</i></p>',
              es: '<h1>HTML Display 1</h1><p>This field is in: <b>ES</b></p><p><i>This should be an italic text</i></p>',
              it: '<h1>HTML Display 1</h1><p>This field is in: <b>IT</b></p><p><i>This should be an italic text</i></p>',
            },
            pdfHide: false,
            pdfWidth: 1,
            uiHide: false,
          },
        },
        {
          id: 'exampleDateInput_1',
          type: FormFieldTypesEnum.DATE_INPUT,
          config: {
            pdfHide: false,
            disabled: false,
            required: true,
            pdfWidth: 1,
            pdfHideIfValueIsEmpty: false,
            label: {
              text: {
                en: 'Date input 1',
                de: 'Date input 1 [DE]',
                tr: 'Date input 1 [TR]',
                fr: 'Date input 1 [FR]',
                es: 'Date input 1 [ES]',
                it: 'Date input 1 [IT]',
              },
              uiHide: false,
              pdfHide: false,
              pdfTextSize: 14,
              pdfTextColor: '#facc2e',
            },
            value: {
              pdfHide: false,
              pdfTextSize: 14,
              pdfTextColor: '#facc2e',
              pdfStartInNewLine: false,
              validators: {
                minDate: '2000-02-22',
                maxDate: '2022-02-22',
              },
            },
            prefill: {
              value: [
                {
                  input: 'none',
                  steps: [['staticDate', '2022-02-22']],
                },
              ],
            },
          },
        },
        {
          id: 'exampleTimeInput_1',
          type: FormFieldTypesEnum.TIME_INPUT,
          config: {
            pdfHide: false,
            disabled: false,
            required: false,
            pdfWidth: 1,
            pdfHideIfValueIsEmpty: false,
            label: {
              text: {
                en: 'Time input 1',
                de: 'Time input 1 [DE]',
                tr: 'Time input 1 [TR]',
                fr: 'Time input 1 [FR]',
                es: 'Time input 1 [ES]',
                it: 'Time input 1 [IT]',
              },
              uiHide: false,
              pdfHide: false,
              pdfTextSize: 14,
              pdfTextColor: '#facc2e',
            },
            value: {
              dropdownInterval: 15,
              pdfHide: false,
              pdfTextSize: 14,
              pdfTextColor: '#facc2e',
              pdfStartInNewLine: false,
            },
            prefill: {
              value: [
                {
                  input: 'none',
                  steps: [['staticTime', '22:22']],
                },
              ],
            },
          },
        },
        {
          id: 'exampleFileUploadInput_1',
          type: FormFieldTypesEnum.FILE_UPLOAD,
          config: {
            pdfHide: false,
            disabled: false,
            pdfHideIfValueIsEmpty: false,
            requiredAmountOfFiles: 0,
            maximumSingleFileSize: 5, // 5 MB
            maximumAccumulatedFileSize: 15, // 15 MB
            acceptedFileTypes: ['image/png', 'image/jpeg'],
            label: {
              text: {
                en: 'File Upload 1 [EN]',
                de: 'File Upload 1 [DE]',
                tr: 'File Upload 1 [TR]',
                fr: 'File Upload 1 [FR]',
                es: 'File Upload 1 [ES]',
                it: 'File Upload 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            value: {
              pdfHide: false,
              pdfTextSize: 12,
              pdfStartInNewLine: false,
            },
          },
        },
        {
          id: 'exampleDateTimeInput_1',
          type: FormFieldTypesEnum.DATE_TIME_INPUT,
          config: {
            pdfHide: false,
            disabled: false,
            required: false,
            pdfWidth: 1,
            pdfHideIfValueIsEmpty: false,
            label: {
              text: {
                en: 'Date time input 1',
                de: 'Date time input 1 [DE]',
                tr: 'Date time input 1 [TR]',
                fr: 'Date time input 1 [FR]',
                es: 'Date time input 1 [ES]',
                it: 'Date time input 1 [IT]',
              },
              uiHide: false,
              pdfHide: false,
              pdfTextSize: 14,
              pdfTextColor: '#facc2e',
            },
            value: {
              dropdownInterval: 15,
              pdfHide: false,
              pdfTextSize: 14,
              pdfTextColor: '#facc2e',
              pdfStartInNewLine: false,
            },
            prefill: {
              value: [
                {
                  input: 'none',
                  steps: [['staticDateTime', '2022-02-22T22:22:00.000Z_Europe/Berlin']],
                },
              ],
            },
          },
        },
        {
          id: 'exampleTimeTrackingListInput_1',
          type: FormFieldTypesEnum.TIME_TRACKING_LIST_INPUT,
          config: {
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            requiredAmountOfEntries: 0,
            maximumDuration: 10080,
            label: {
              text: {
                en: 'Time Tracking',
                de: 'Time Tracking [DE]',
                tr: 'Time Tracking [TR]',
                fr: 'Time Tracking [FR]',
                es: 'Time Tracking [ES]',
                it: 'Time Tracking [IT]',
              },
            },
            fields: {
              timeTrackingTypeStaticSingleSelect: {
                pdfHide: false,
              },
              startDateTimeInput: {
                pdfHide: false,
              },
              endDateTimeInput: {
                pdfHide: false,
              },
              breakNumericInput: {
                disabled: false,
                required: true,
                pdfHide: false,
              },
              durationDisplay: {
                disabled: false,
                pdfHide: false,
              },
              distanceNumericInput: {
                disabled: false,
                required: true,
                pdfHide: false,
              },
              technicianUserSingleSelect: {
                disabled: false,
                required: true,
                pdfHide: false,
                pdfPrintEmailAddress: true,
              },
              commentMultiLineTextInput: {
                disabled: false,
                required: true,
                pdfHide: false,
              },
            },
          },
        },
        {
          id: 'exampleFieldRepeater_1',
          type: FormFieldTypesEnum.FIELD_REPEATER,
          config: {
            label: {
              text: {
                en: 'Field Repeater 1',
                de: 'Feld Liste 1',
                tr: 'Field Repeater 1 [TR]',
                fr: 'Field Repeater 1 [FR]',
                es: 'Field Repeater 1 [ES]',
                it: 'Field Repeater 1 [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            helpTextBefore: {
              en: 'Example helptext before',
              de: 'Beispiel Hilfstext davor',
              tr: 'Example helptext before [TR]',
              fr: 'Example helptext before [FR]',
              es: 'Example helptext before [ES]',
              it: 'Example helptext before [IT]',
            },
            helpTextAfter: {
              en: 'Example helptext after',
              de: 'Beispiel Hilfstext danach',
              tr: 'Example helptext after [TR]',
              fr: 'Example helptext after [FR]',
              es: 'Example helptext after [ES]',
              it: 'Example helptext after [IT]',
            },
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            requiredAmountOfEntries: 2,
            fields: [
              {
                id: 'exampleFieldRepeater_1_singleLineInput_1',
                type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
                config: {
                  required: true,
                  label: {
                    text: {
                      en: 'Input 1',
                      de: 'Eingabe 1',
                      tr: 'Input 1 [TR]',
                      fr: 'Input 1 [FR]',
                      es: 'Input 1 [ES]',
                      it: 'Input 1 [IT]',
                    },
                  },
                  uiHideInRepeaterCardDisplay: false,
                  value: {
                    validators: {
                      emailAddress: true,
                    },
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_singleLineInput_2',
                type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
                config: {
                  required: true,
                  label: {
                    text: {
                      en: 'Input 2',
                      de: 'Eingabe 2',
                      tr: 'Input 2 [TR]',
                      fr: 'Input 2 [FR]',
                      es: 'Input 2 [ES]',
                      it: 'Input 2 [IT]',
                    },
                  },
                  uiHideInRepeaterCardDisplay: false,
                },
              },
              {
                id: 'exampleFieldRepeater_1_singleLineInput_3',
                type: FormFieldTypesEnum.SINGLE_LINE_TEXT_INPUT,
                config: {
                  required: true,
                  label: {
                    text: {
                      en: 'Input 3',
                      de: 'Eingabe 3',
                      tr: 'Input 3 [TR]',
                      fr: 'Input 3 [FR]',
                      es: 'Input 3 [ES]',
                      it: 'Input 3 [IT]',
                    },
                  },
                  uiHideInRepeaterCardDisplay: true,
                  value: {
                    validators: {
                      regex: {
                        pattern: '^[A-Z]*$',
                        errorText: {
                          en: 'Only uppercase letters allowed',
                          de: 'Only uppercase letters allowed [DE]',
                          tr: 'Only uppercase letters allowed [TR]',
                          fr: 'Only uppercase letters allowed [FR]',
                          es: 'Only uppercase letters allowed [ES]',
                          it: 'Only uppercase letters allowed [IT]',
                        },
                      },
                    },
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_exampleStaticSingleSelect_1',
                type: FormFieldTypesEnum.STATIC_SINGLE_SELECT,
                config: {
                  required: true,
                  label: {
                    text: {
                      en: 'Static Single Select 1',
                      de: 'Static Single Select 1 [DE]',
                      tr: 'Static Single Select 1 [TR]',
                      fr: 'Static Single Select 1 [FR]',
                      es: 'Static Single Select 1 [ES]',
                      it: 'Static Single Select 1 [IT]',
                    },
                  },
                  value: {
                    options: {
                      exampleOptionOne: {
                        en: 'Example option 1',
                        de: 'Example option 1 [DE]',
                        tr: 'Example option 1 [TR]',
                        fr: 'Example option 1 [FR]',
                        es: 'Example option 1 [ES]',
                        it: 'Example option 1 [IT]',
                      },
                      exampleOptionTwo: {
                        en: 'Example option 2',
                        de: 'Example option 2 [DE]',
                        tr: 'Example option 2 [TR]',
                        fr: 'Example option 2 [FR]',
                        es: 'Example option 2 [ES]',
                        it: 'Example option 2 [IT]',
                      },
                    },
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_exampleStaticMultiSelect_1',
                type: FormFieldTypesEnum.STATIC_MULTI_SELECT,
                config: {
                  label: {
                    text: {
                      en: 'Static Multi Select 1',
                      de: 'Static Multi Select 1 [DE]',
                      tr: 'Static Multi Select 1 [TR]',
                      fr: 'Static Multi Select 1 [FR]',
                      es: 'Static Multi Select 1 [ES]',
                      it: 'Static Multi Select 1 [IT]',
                    },
                  },
                  value: {
                    options: {
                      exampleOptionOne: {
                        en: 'Example option 1',
                        de: 'Example option 1 [DE]',
                        tr: 'Example option 1 [TR]',
                        fr: 'Example option 1 [FR]',
                        es: 'Example option 1 [ES]',
                        it: 'Example option 1 [IT]',
                      },
                      exampleOptionTwo: {
                        en: 'Example option 2',
                        de: 'Example option 2 [DE]',
                        tr: 'Example option 2 [TR]',
                        fr: 'Example option 2 [FR]',
                        es: 'Example option 2 [ES]',
                        it: 'Example option 2 [IT]',
                      },
                    },
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_exampleCompanySingleSelect_1',
                type: FormFieldTypesEnum.ORGANIZATION_SINGLE_SELECT,
                config: {
                  label: {
                    text: {
                      en: 'Company Single Select 1',
                      de: 'Company Single Select 1 [DE]',
                      tr: 'Company Single Select 1 [TR]',
                      fr: 'Company Single Select 1 [FR]',
                      es: 'Company Single Select 1 [ES]',
                      it: 'Company Single Select 1 [IT]',
                    },
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_multiLineTextLineInput_1',
                type: FormFieldTypesEnum.MULTI_LINE_TEXT_INPUT,
                config: {
                  required: true,
                  label: {
                    text: {
                      en: 'MultiLineTextInput 1',
                      de: 'Mehrzeilige Eingabe 1',
                      tr: 'MultiLineTextInput 1 [TR]',
                      fr: 'MultiLineTextInput 1 [FR]',
                      es: 'MultiLineTextInput 1 [ES]',
                      it: 'MultiLineTextInput 1 [IT]',
                    },
                  },
                  uiHideInRepeaterCardDisplay: false,
                  value: {
                    validators: {
                      minCharacters: 3,
                    },
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_exampleHtmlDisplay_1',
                type: FormFieldTypesEnum.HTML_DISPLAY,
                config: {
                  text: {
                    en: '<h1>HTML Display 1</h1><p>This field is in: <b>EN</b></p><p><i>This should be an italic text</i></p>',
                    de: '<h1>HTML Display 1</h1><p>This field is in: <b>DE</b></p><p><i>This should be an italic text</i></p>',
                    tr: '<h1>HTML Display 1</h1><p>This field is in: <b>TR</b></p><p><i>This should be an italic text</i></p>',
                    fr: '<h1>HTML Display 1</h1><p>This field is in: <b>FR</b></p><p><i>This should be an italic text</i></p>',
                    es: '<h1>HTML Display 1</h1><p>This field is in: <b>ES</b></p><p><i>This should be an italic text</i></p>',
                    it: '<h1>HTML Display 1</h1><p>This field is in: <b>IT</b></p><p><i>This should be an italic text</i></p>',
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_exampleBooleanInput_1',
                type: FormFieldTypesEnum.BOOLEAN_INPUT,
                config: {
                  label: {
                    text: {
                      en: 'Boolean input 1',
                      de: 'Boolean input 1 [DE]',
                      tr: 'Boolean input 1 [TR]',
                      fr: 'Boolean input 1 [FR]',
                      es: 'Boolean input 1 [ES]',
                      it: 'Boolean input 1 [IT]',
                    },
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_exampleAddressInput_1',
                type: FormFieldTypesEnum.ADDRESS_INPUT,
                config: {
                  required: {
                    street: false,
                    streetNumber: false,
                    supplement: false,
                    zipPostCode: false,
                    city: false,
                    state: false,
                    country: false,
                  },
                  label: {
                    text: {
                      en: 'Address Input 1',
                      de: 'Anschrift Input 1',
                      tr: 'Address Input 1 [TR]',
                      fr: 'Address Input 1 [FR]',
                      es: 'Address Input 1 [ES]',
                      it: 'Address Input 1 [IT]',
                    },
                    pdfHide: false,
                    pdfTextColor: '#facc2e',
                    pdfTextSize: 14,
                  },
                  value: {
                    pdfHide: false,
                    pdfStartInNewLine: false,
                    pdfAddLineBreaks: false,
                    pdfTextColor: '#facc2e',
                    pdfTextSize: 14,
                  },
                  disabled: false,
                  pdfHide: false,
                  pdfWidth: 1,
                  pdfHideIfValueIsEmpty: false,
                  uiHideInRepeaterCardDisplay: false,
                },
              },
              {
                id: 'exampleFieldRepeater_1_dateInput_1',
                type: FormFieldTypesEnum.DATE_INPUT,
                config: {
                  required: true,
                  label: {
                    text: {
                      en: 'Date Input 1',
                      de: 'Date Input 1 [DE]',
                      tr: 'Date Input 1 [TR]',
                      fr: 'Date Input 1 [FR]',
                      es: 'Date Input 1 [ES]',
                      it: 'Date Input 1 [IT]',
                    },
                    uiHide: false,
                    pdfHide: false,
                    pdfTextSize: 14,
                    pdfTextColor: '#facc2e',
                  },
                  uiHideInRepeaterCardDisplay: false,
                  value: {
                    pdfHide: false,
                    pdfTextSize: 14,
                    pdfTextColor: '#facc2e',
                    pdfStartInNewLine: false,
                    validators: {
                      minDate: '2000-02-22',
                      maxDate: '2022-02-22',
                    },
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_timeInput_1',
                type: FormFieldTypesEnum.TIME_INPUT,
                config: {
                  required: false,
                  label: {
                    text: {
                      en: 'Time Input 1',
                      de: 'Time Input 1 [DE]',
                      tr: 'Time Input 1 [TR]',
                      fr: 'Time Input 1 [FR]',
                      es: 'Time Input 1 [ES]',
                      it: 'Time Input 1 [IT]',
                    },
                    uiHide: false,
                    pdfHide: false,
                    pdfTextSize: 14,
                    pdfTextColor: '#facc2e',
                  },
                  uiHideInRepeaterCardDisplay: false,
                  value: {
                    dropdownInterval: 15,
                    pdfHide: false,
                    pdfTextSize: 14,
                    pdfTextColor: '#facc2e',
                    pdfStartInNewLine: false,
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_exampleAssetSingleSelect_1',
                type: FormFieldTypesEnum.ASSET_SINGLE_SELECT,
                config: {
                  required: false,
                  disabled: false,
                  pdfHide: false,
                  pdfHideIfValueIsEmpty: false,
                  uiHideInRepeaterCardDisplay: false,
                  value: {
                    disableAssetFormInstanceRelationship: false,
                    disableCreation: false,
                    pdfHide: false,
                    pdfStartInNewLine: false,
                    pdfTextColor: '#facc2e',
                    pdfTextSize: 14,
                  },
                  label: {
                    text: {
                      en: 'Asset Select 3',
                      de: 'Asset Auswahl 3',
                      tr: 'Asset Select 3 [TR]',
                      fr: 'Asset Select 3 [FR]',
                      es: 'Asset Select 3 [ES]',
                      it: 'Asset Select 3 [IT]',
                    },
                    pdfHide: false,
                    pdfTextColor: '#facc2e',
                    pdfTextSize: 14,
                    uiHide: false,
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_exampleUserSingleSelect_1',
                type: FormFieldTypesEnum.CONTACT_SINGLE_SELECT,
                config: {
                  required: false,
                  disabled: false,
                  pdfHide: false,
                  pdfHideIfValueIsEmpty: false,
                  uiHideInRepeaterCardDisplay: false,
                  value: {
                    pdfHide: false,
                    pdfStartInNewLine: false,
                    pdfTextColor: '#facc2e',
                    pdfTextSize: 14,
                    pdfPrintEmailAddress: true,
                  },
                  label: {
                    text: {
                      en: 'User Select 1',
                      de: 'User Auswahl 1',
                      tr: 'User Select 1 [TR]',
                      fr: 'User Select 1 [FR]',
                      es: 'User Select 1 [ES]',
                      it: 'User Select 1 [IT]',
                    },
                    pdfHide: false,
                    pdfTextColor: '#facc2e',
                    pdfTextSize: 14,
                    uiHide: false,
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_headingDisplay_1',
                type: FormFieldTypesEnum.HEADLINE_DISPLAY,
                config: {
                  text: {
                    en: 'Headline display element sample',
                    de: 'Headline display element sample [DE]',
                    tr: 'Headline display element sample [TR]',
                    fr: 'Headline display element sample [FR]',
                    es: 'Headline display element sample [ES]',
                    it: 'Headline display element sample [IT]',
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_phoneNumberInput_1',
                type: FormFieldTypesEnum.PHONE_NUMBER_INPUT,
                config: {
                  disabled: false,
                  required: true,
                  pdfHide: false,
                  pdfHideIfValueIsEmpty: false,
                  uiHideInRepeaterCardDisplay: false,
                  pdfWidth: 1,
                  value: {
                    pdfHide: false,
                    pdfStartInNewLine: false,
                    pdfTextColor: '#facc2e',
                    pdfTextSize: 14,
                    validators: {
                      minCharacters: 5,
                    },
                  },
                  label: {
                    text: {
                      en: 'Phone number input min validation',
                      de: 'Phone number input min validation [DE]',
                      tr: 'Phone number input min validation [TR]',
                      fr: 'Phone number input min validation [FR]',
                      es: 'Phone number input min validation [ES]',
                      it: 'Phone number input min validation [IT]',
                    },
                    pdfHide: false,
                    pdfTextColor: '#facc2e',
                    pdfTextSize: 14,
                  },
                  placeHolderText: {
                    en: 'Phone number input placeholder',
                    de: 'Phone number input placeholder [DE]',
                    tr: 'Phone number input placeholder [TR]',
                    fr: 'Phone number input placeholder [FR]',
                    es: 'Phone number input placeholder [ES]',
                    it: 'Phone number input placeholder [IT]',
                  },
                },
              },
              {
                id: 'exampleFieldRepeater_1_dateTimeInput_1',
                type: FormFieldTypesEnum.DATE_TIME_INPUT,
                config: {
                  required: false,
                  label: {
                    text: {
                      en: 'Date time input 1',
                      de: 'Date time input 1 [DE]',
                      tr: 'Date time input 1 [TR]',
                      fr: 'Date time input 1 [FR]',
                      es: 'Date time input 1 [ES]',
                      it: 'Date time input 1 [IT]',
                    },
                    uiHide: false,
                    pdfHide: false,
                    pdfTextSize: 14,
                    pdfTextColor: '#facc2e',
                  },
                  uiHideInRepeaterCardDisplay: false,
                  value: {
                    dropdownInterval: 15,
                    pdfHide: false,
                    pdfTextSize: 14,
                    pdfTextColor: '#facc2e',
                    pdfStartInNewLine: false,
                  },
                },
              },
            ],
          },
        },
        {
          id: 'exampleHeadlineDisplay_1',
          type: FormFieldTypesEnum.HEADLINE_DISPLAY,
          config: {
            text: {
              en: 'Headline display element sample',
              de: 'Headline display element sample [DE]',
              tr: 'Headline display element sample [TR]',
              fr: 'Headline display element sample [FR]',
              es: 'Headline display element sample [ES]',
              it: 'Headline display element sample [IT]',
            },
            uiHide: false,
            type: 'H1',
            pdfHide: false,
            pdfTextColor: '#facc2e',
            pdfWidth: 1,
          },
        },
        {
          id: 'examplePhoneNumberInput_1',
          type: FormFieldTypesEnum.PHONE_NUMBER_INPUT,
          config: {
            disabled: false,
            required: true,
            pdfHide: false,
            pdfHideIfValueIsEmpty: false,
            pdfWidth: 1,
            value: {
              pdfHide: false,
              pdfStartInNewLine: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
              validators: {
                maxCharacters: 11,
              },
            },
            prefill: {
              value: [
                {
                  input: 'none',
                  steps: [
                    [
                      'staticPhoneNumber',
                      {
                        countryPrefix: '49',
                        number: '1753463937',
                      },
                    ],
                  ],
                },
              ],
            },
            label: {
              text: {
                en: 'Phone number input required',
                de: 'Phone number input required [DE]',
                tr: 'Phone number input required [TR]',
                fr: 'Phone number input required [FR]',
                es: 'Phone number input required [ES]',
                it: 'Phone number input required [IT]',
              },
              pdfHide: false,
              pdfTextColor: '#facc2e',
              pdfTextSize: 14,
            },
            placeHolderText: {
              en: 'Phone number input placeholder',
              de: 'Phone number input placeholder [DE]',
              tr: 'Phone number input placeholder [TR]',
              fr: 'Phone number input placeholder [FR]',
              es: 'Phone number input placeholder [ES]',
              it: 'Phone number input placeholder [IT]',
            },
          },
        },
      ],
    },
    {
      id: 'exampleSignatureSection',
      type: FormSectionTypesEnum.SIGNATURE_SECTION,
      config: {
        required: true,
        fields: {
          dateInput: {
            disable: false,
            pdfHide: false,
            required: false,
          },
          locationSingleLineTextInput: {
            disable: false,
            pdfHide: false,
            required: false,
          },
          nameSingleLineTextInput: {
            disable: false,
            pdfHide: false,
            capitalizeFirstLetterOfEveryWord: false,
            required: false,
          },
        },
        headlineText: {
          en: 'Example Signature Section',
          de: 'Beispiel Signatur Sektion',
          tr: 'Example Signature Section [TR]',
          fr: 'Example Signature Section [FR]',
          es: 'Example Signature Section [ES]',
          it: 'Example Signature Section [IT]',
        },
        helpTextHtmlAbove: {
          text: {
            en: '<b>Example HTML helptext before</b>',
            de: '<b>Beispiel HTML Hilfstext davor</b>',
            tr: '<b>Example HTML helptext before [TR]</b>',
            fr: '<b>Example HTML helptext before [FR]</b>',
            es: '<b>Example HTML helptext before [ES]</b>',
            it: '<b>Example HTML helptext before [IT]</b>',
          },
          pdfHide: true,
        },
        helpTextHtmlBelow: {
          text: {
            en: '<b>Example HTML helptext below</b>',
            de: '<b>Beispiel HTML Hilfstext derunter</b>',
            tr: '<b>Example HTML helptext below [TR]</b>',
            fr: '<b>Example HTML helptext below [FR]</b>',
            es: '<b>Example HTML helptext below [ES]</b>',
            it: '<b>Example HTML helptext below [IT]</b>',
          },
          pdfHide: true,
        },
        hideDownloadPdfButton: false,
        hidePreviewPdfButton: false,
        pdfHide: false,
        pdfHideIfValueIsEmpty: false,
        prefill: {
          name: [
            {
              input: 'currentUser',
              steps: ['userToFullNameString'],
            },
          ],
          location: [
            {
              input: 'assetId',
              steps: ['assetIdToAsset', 'assetToLocationAddress', 'addressToCityString'],
            },
            {
              input: 'assetId',
              steps: [
                'assetIdToAsset',
                'assetToCustomerAccountId',
                'accountIdToAccount',
                'accountToBillingAddressAddress',
                'addressToCityString',
              ],
            },
            {
              input: 'organizationId',
              steps: [
                'accountIdToAccount',
                'accountToBillingAddressAddress',
                'addressToCityString',
              ],
            },
            {
              input: 'none',
              steps: [['staticString', 'Default Location']],
            },
          ],
          date: [
            {
              input: 'creationDateTime',
              steps: ['dateTimeToDate'],
            },
          ],
        },
      },
    },
    {
      id: 'exampleEmailSection',
      type: FormSectionTypesEnum.EMAIL_SECTION,
      config: {
        whitelistedDomains: [],
        headlineText: {
          en: 'Example Email Section',
          de: 'Beispiel Email Sektion',
          tr: 'Example Email Section [TR]',
          fr: 'Example Email Section [FR]',
          es: 'Example Email Section [ES]',
          it: 'Example Email Section [IT]',
        },
        helpTextHtmlAbove: {
          en: '<b>Example HTML helptext before</b>',
          de: '<b>Beispiel HTML Hilfstext davor</b>',
          tr: '<b>Example HTML helptext before [TR]</b>',
          fr: '<b>Example HTML helptext before [FR]</b>',
          es: '<b>Example HTML helptext before [ES]</b>',
          it: '<b>Example HTML helptext before [IT]</b>',
        },
        helpTextHtmlBelow: {
          en: '<b>Example HTML helptext below</b>',
          de: '<b>Beispiel HTML Hilfstext derunter</b>',
          tr: '<b>Example HTML helptext below [TR]</b>',
          fr: '<b>Example HTML helptext below [FR]</b>',
          es: '<b>Example HTML helptext below [ES]</b>',
          it: '<b>Example HTML helptext below [IT]</b>',
        },
        requiredAmountOfOutgoingMails: 2,
        hidePreviewPdfButton: false,
        hideDownloadPdfButton: false,
        emailDialogPrefill: {
          bcc: {
            assignee: false,
            creator: false,
            currentUser: true,
            staticEmails: [],
          },
          cc: {
            assignee: false,
            creator: true,
            currentUser: false,
            staticEmails: [],
          },
          to: {
            assignee: true,
            creator: false,
            currentUser: false,
            staticEmails: [],
          },
          body: {
            de: 'Dies ist der Standardinhalt\nUnd das sollte in einer neuen Zeile stehen\nGrüße,\nOnlyOEM GmbH',
            en: 'This is the default content\nAnd this should be in a new line\nRegards,\nOnlyOEM GmbH',
            it: '',
            es: '',
            fr: '',
            tr: '',
          },
          subject: {
            de: 'Ihr Beispielformular',
            en: 'Your example form',
            es: '',
            fr: '',
            tr: '',
            it: '',
          },
        },
      },
    },
  ],
};
