import { ActivityFieldTypeEnum, ActivityObjectSchema } from '@remberg/activities/common/main';
import {
  AssetActivityFieldTypeEnum,
  AssetSpecificFieldTypeEnum,
} from './asset-activity-field-type.enum';
import { AssetActivityFieldEnum } from './asset-activity-field.enum';

export const ASSET_ACTIVITY_OBJECT_SCHEMA = {
  [AssetActivityFieldEnum.ASSET_TYPE_ID]: ActivityFieldTypeEnum.ASSET_TYPE,
  [AssetActivityFieldEnum.PARENT_ID]: ActivityFieldTypeEnum.ASSET,
  [AssetActivityFieldEnum.ANCESTOR_IDS]: ActivityFieldTypeEnum.ASSET_ARRAY,
  [AssetActivityFieldEnum.SERIAL_NUMBER]: ActivityFieldTypeEnum.TEXT,
  [AssetActivityFieldEnum.NAME]: ActivityFieldTypeEnum.TEXT,
  [AssetActivityFieldEnum.RELATED_ORGANIZATION_IDS]: ActivityFieldTypeEnum.ORGANIZATION_ARRAY,
  [AssetActivityFieldEnum.TENANT_OWNER_CONTACT_ID]: ActivityFieldTypeEnum.CONTACT,
  [AssetActivityFieldEnum.RELATED_CONTACT_IDS]: ActivityFieldTypeEnum.CONTACT_ARRAY,
  [AssetActivityFieldEnum.LOCATION]: ActivityFieldTypeEnum.ADDRESS,
  [AssetActivityFieldEnum.USER_GROUP_IDS]: ActivityFieldTypeEnum.USER_GROUP_ARRAY,
  [AssetActivityFieldEnum.CUSTOM_PROPERTY_VALUES]: ActivityFieldTypeEnum.CUSTOM_PROPERTIES,
  // Fields with AssetSpecificFieldTypeEnum
  [AssetActivityFieldEnum.STATUS]: AssetSpecificFieldTypeEnum.STATUS,
} satisfies ActivityObjectSchema<AssetActivityFieldEnum, AssetActivityFieldTypeEnum>;
