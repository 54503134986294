import { KnownDataTypeEnum } from './known-data-types';
import { StepNameWithArguments } from './steps';

/** This mapping defines input and output data types for all prefill steps.
 *  It is needed for validating prefilling rules. Casting properties to const is necessary for typescript
 *  to infer the exact value as type (e.g. KnownDataTypeEnum.ASSET_ID instead of just KnownDataTypeEnum).
 */
export const KNOWN_PREFILLING_STEPS = {
  // Account -> OrganizationRaw
  accountIdToAccount: {
    input: [KnownDataTypeEnum.ORGANIZATION_ID],
    output: KnownDataTypeEnum.ORGANIZATION,
  },
  accountToBillingAddressAddress: {
    input: [KnownDataTypeEnum.ORGANIZATION],
    output: KnownDataTypeEnum.ADDRESS,
  },
  accountToCustomerNumber: {
    input: [KnownDataTypeEnum.ORGANIZATION],
    output: KnownDataTypeEnum.STRING,
  },

  // User -> Contact
  userIdToUser: {
    input: [KnownDataTypeEnum.CONTACT_ID],
    output: KnownDataTypeEnum.CONTACT_BASIC,
  },
  userToFullNameString: {
    input: [KnownDataTypeEnum.CONTACT_BASIC],
    output: KnownDataTypeEnum.STRING,
  },

  // Asset
  assetIdToAsset: {
    input: [KnownDataTypeEnum.ASSET_ID],
    output: KnownDataTypeEnum.ASSET,
  },
  assetToLocationAddress: {
    input: [KnownDataTypeEnum.ASSET],
    output: KnownDataTypeEnum.ADDRESS,
  },
  assetToManufacturerPersonUserId: {
    input: [KnownDataTypeEnum.ASSET],
    output: KnownDataTypeEnum.CONTACT_ID,
  },
  assetToCustomerAccountId: {
    input: [KnownDataTypeEnum.ASSET],
    output: KnownDataTypeEnum.ORGANIZATION_ID,
  },
  assetToCustomerPersonUserId: {
    input: [KnownDataTypeEnum.ASSET],
    output: KnownDataTypeEnum.CONTACT_ID,
  },
  assetToAssetTypeNameString: {
    input: [KnownDataTypeEnum.ASSET],
    output: KnownDataTypeEnum.STRING,
  },
  assetToParentAssetId: {
    input: [KnownDataTypeEnum.ASSET],
    output: KnownDataTypeEnum.ASSET_ID,
  },
  assetToCustomPropertyValue: {
    input: [KnownDataTypeEnum.ASSET, KnownDataTypeEnum.STRING],
    output: KnownDataTypeEnum.UNKNOWN,
  },

  // WorkOrder
  workOrderIdToWorkOrder: {
    input: [KnownDataTypeEnum.WORK_ORDER_ID],
    output: KnownDataTypeEnum.WORK_ORDER,
  },
  workOrderToAssetIds: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.ASSET_IDS,
  },
  workOrderToLocationAddress: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.ADDRESS,
  },
  workOrderToTasks: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.TASKS,
  },
  workOrderToContacts: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.CONTACTS,
  },
  contactsToPersonListEntries: {
    input: [KnownDataTypeEnum.CONTACTS],
    output: KnownDataTypeEnum.PERSON_LIST_ENTRIES,
  },
  workOrderToTitleString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToDescriptionString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToDueDate: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.REMBERG_DATE,
  },
  workOrderToStartDate: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.REMBERG_DATE,
  },
  workOrderToEndDate: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.REMBERG_DATE,
  },
  workOrderToERPReferenceString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToResponsibleUserId: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.CONTACT_ID,
  },
  // TODO remove after v.2.79 mobile release. Should be replaced by workOrderToTypeReferenceString
  workOrderToTypeNumberString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToTypeReferenceString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },

  // enum that will be given as string
  workOrderToPriorityString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToPerformByUserId: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.CONTACT_ID,
  },
  workOrderToAdditionalContactInformationString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToCaseSubjectString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToCaseTicketAndSubjectString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToTicketSubjectString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToTicketCounterAndSubjectString: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  workOrderToOrganizationAccountId: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.ORGANIZATION_ID,
  },
  workOrderToCustomPropertyValue: {
    input: [KnownDataTypeEnum.WORK_ORDER, KnownDataTypeEnum.STRING],
    output: KnownDataTypeEnum.UNKNOWN,
  },
  workOrderToWorkOrderCounter: {
    input: [KnownDataTypeEnum.WORK_ORDER],
    output: KnownDataTypeEnum.STRING,
  },
  // Static
  addressToCityString: {
    input: [KnownDataTypeEnum.ADDRESS],
    output: KnownDataTypeEnum.STRING,
  },
  dateTimeToDate: {
    input: [KnownDataTypeEnum.REMBERG_DATETIME],
    output: KnownDataTypeEnum.REMBERG_DATE,
  },
  staticString: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.STRING],
    output: KnownDataTypeEnum.STRING,
  },
  staticStringArray: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.ARRAY_OF_STRINGS],
    output: KnownDataTypeEnum.ARRAY_OF_STRINGS,
  },
  staticBoolean: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.BOOLEAN],
    output: KnownDataTypeEnum.BOOLEAN,
  },
  staticAddress: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.ADDRESS],
    output: KnownDataTypeEnum.ADDRESS,
  },
  staticDate: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.REMBERG_DATE],
    output: KnownDataTypeEnum.REMBERG_DATE,
  },
  staticTime: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.REMBERG_TIME],
    output: KnownDataTypeEnum.REMBERG_TIME,
  },
  staticPhoneNumber: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.PHONE_NUMBER],
    output: KnownDataTypeEnum.PHONE_NUMBER,
  },
  staticDateTime: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.REMBERG_DATETIME],
    output: KnownDataTypeEnum.REMBERG_DATETIME,
  },
  staticTasks: {
    input: [KnownDataTypeEnum.NONE, KnownDataTypeEnum.TASKS],
    output: KnownDataTypeEnum.TASKS,
  },

  // custom properties
  customPropertyValueToString: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.STRING,
  },
  // this can be used for custom property SELECT with multi select checked
  customPropertyValueToArrayOfStrings: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.ARRAY_OF_STRINGS,
  },
  customPropertyValueToNumberString: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.STRING,
  },
  customPropertyValueToNumber: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.NUMBER,
  },
  customPropertyValueToBoolean: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.BOOLEAN,
  },
  customPropertyValueToAssetId: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.ASSET_ID,
  },
  customPropertyValueToAccountId: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.ORGANIZATION_ID,
  },
  customPropertyValueToUserId: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.CONTACT_ID,
  },
  customPropertyValueToDate: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.REMBERG_DATE,
  },
  customPropertyValueToDateTime: {
    input: [KnownDataTypeEnum.UNKNOWN],
    output: KnownDataTypeEnum.REMBERG_DATETIME,
  },
} as const;

export type KnownPrefillingSteps = typeof KNOWN_PREFILLING_STEPS;
export type PrefillingStepName = keyof KnownPrefillingSteps;
export type PrefillingStepNameWithArguments = StepNameWithArguments<KnownPrefillingSteps>;
