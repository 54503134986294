export enum Tickets2PopulateEnum {
  ASSIGNED_CONTACT = 'assignedContact',
  ASSIGNED_USER_GROUP = 'assignedUserGroup',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CATEGORY = 'category',
  CONTACTS = 'contacts',
  TICKETS2 = 'tickets2',
  RELATED_ORGANIZATIONS = 'relatedOrganizations',
  PORTAL_ACCESS_ORGANIZATIONS = 'portalAccessOrganizations',
  ASSETS = 'assets',
  RELATED_PARTS = 'relatedParts',
  CUSTOM_PROPERTY_VALUES = 'customPropertyValues',
  CREATED_BY_EMAIL_CONTACT = 'createdByEmailContact',
}
