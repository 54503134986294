import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { MaintenancePlan2Permissions } from '@remberg/users/common/main';

export enum MaintenancePlanPermissionsEnum {
  MAINTENANCE_PLANS_ENABLED = 'maintenancePlansTemporary_enabled',
  MAINTENANCE_PLANS_CREATE = 'maintenancePlansTemporary_create',
  MAINTENANCE_PLANS_UPDATE = 'maintenancePlansTemporary_update',
  MAINTENANCE_PLANS_DELETE = 'maintenancePlansTemporary_delete',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.MAINTENANCE_PLANS_TEMPORARY}_${keyof MaintenancePlan2Permissions}`,
    `${MaintenancePlanPermissionsEnum}`
  >
>;
