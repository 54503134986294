export enum MaintenancePlanFilterFieldEnum {
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',

  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',

  STATUS = 'status',

  RELATED_ASSET = 'relatedAsset',
  RELATED_ORGANIZATION = 'relatedOrganization',

  WORK_ORDER_TEMPLATE_PRIORITY = 'workOrderTemplatePriority',
  WORK_ORDER_TEMPLATE_STATUS = 'workOrderTemplateStatus',
  WORK_ORDER_TEMPLATE_TYPE = 'workOrderTemplateType',
  WORK_ORDER_TEMPLATE_RESPONSIBLE_GROUP = 'workOrderTemplateResponsibleGroup',
  WORK_ORDER_TEMPLATE_RESPONSIBLE_CONTACT = 'workOrderTemplateResponsibleContact',
  WORK_ORDER_TEMPLATE_ASSIGNED_CONTACTS = 'workOrderTemplateAssignedContacts',
  WORK_ORDER_TEMPLATE_ASSIGNED_GROUPS = 'workOrderTemplateAssignedGroups',
}
