export enum NotificationTypeEnum {
  // region cases
  TicketAssignment = 'Ticket-Assignment',
  TicketChange = 'Ticket-Change',
  TicketCreated = 'Ticket-Created',
  TicketMention = 'Ticket-Mention',
  // endregion cases
  // region tickets2
  Ticket2Assignment = 'Ticket2-Assignment',
  Ticket2Change = 'Ticket2-Change',
  Ticket2Created = 'Ticket2-Created',
  Ticket2Mention = 'Ticket2-Mention',
  //end region
  // region tasks
  TaskAssignment = 'Task-Assignment',
  TaskOverdue = 'Task-Overdue',
  TaskUpdate = 'Task-Update',
  // endregion tasks
  // region assets
  AssetAssignPerson = 'Asset-AssignPerson',
  AssetUpdate = 'Asset-Update',
  AssetMention = 'Asset-Mention',
  // endregion assets
  // region work orders 2
  WorkOrder2Assignment = 'WorkOrder2-Assignment',
  WorkOrder2Update = 'WorkOrder2-Update',
  WorkOrder2OverDue = 'WorkOrder2-OverDue',
  WorkOrder2DueDateApproaching = 'WorkOrder2-DueDateApproaching',
  WorkOrder2Mention = 'WorkOrder2-Mention',
  WorkOrder2PlanningUpdate = 'WorkOrder2-PlanningUpdate',
  // endregion work orders 2

  // region appointments
  AppointmentAssignment = 'Appointment-Assignment',
  AppointmentUpdate = 'Appointment-Update',
  // endregion appointments

  // region others
  MaintenancePlanMention = 'MaintenancePlan-Mention',
  FormInstanceMention = 'FormData-Mention',
  ExportReady = 'Export-Ready',
  AccountRequestAccess = 'Account-RequestAccess',
  EmailFailed = 'Email-Failed',
  // endregion others
}
