import { Ticket2Basic } from '../definitions';

export function mapToTicket2Basic<T extends Ticket2Basic>(ticket2: T): Ticket2Basic {
  return {
    _id: ticket2._id,
    counter: ticket2.counter,
    subject: ticket2.subject,
    status: ticket2.status,
  };
}
