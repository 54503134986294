import { Injectable } from '@angular/core';
import {
  CustomPropertyTypesEnum,
  CustomPropertyValue,
} from '@remberg/custom-properties/common/main';
import { StepsProvider } from '@remberg/forms/common/main';
import { RembergDate, RembergDateTime } from '@remberg/global/common/core';
import { PrefillingStepFunctions } from './interfaces';

@Injectable()
export class CustomPropertyValue2PrefillingStepsProvider
  implements StepsProvider<PrefillingStepFunctions>
{
  public getSteps(): Partial<PrefillingStepFunctions> {
    return {
      customPropertyValueToString,
      customPropertyValueToNumber: (cVal: unknown | undefined) =>
        isCustomProperty(cVal) && typeof cVal.value === 'number' ? cVal.value : undefined,
      customPropertyValueToNumberString: (cVal: unknown | undefined) =>
        isCustomProperty(cVal) && typeof cVal.value === 'number' ? cVal.value + '' : undefined,
      customPropertyValueToBoolean: (cVal: unknown | undefined) =>
        isCustomProperty(cVal) && typeof cVal.value === 'boolean' ? cVal.value : undefined,
      customPropertyValueToArrayOfStrings,
      customPropertyValueToAssetId,
      customPropertyValueToUserId,
      customPropertyValueToAccountId,
      customPropertyValueToDate,
      customPropertyValueToDateTime,
    };
  }
}

function isCustomProperty(cPropVal: unknown | undefined): cPropVal is CustomPropertyValue {
  return (
    !!cPropVal &&
    !!(cPropVal as CustomPropertyValue).customPropertyId &&
    !!(cPropVal as CustomPropertyValue).propertyType
  );
}

function customPropertyValueToDateTime(cVal: unknown | undefined): RembergDateTime | undefined {
  return isCustomProperty(cVal) && cVal.propertyType === CustomPropertyTypesEnum.REMBERG_DATETIME
    ? cVal.value
    : undefined;
}

function customPropertyValueToDate(cVal: unknown | undefined): RembergDate | undefined {
  return isCustomProperty(cVal) && cVal.propertyType === CustomPropertyTypesEnum.REMBERG_DATE
    ? cVal.value
    : undefined;
}

function customPropertyValueToAccountId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    cVal.propertyType === CustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT
    ? cVal.value[0]
    : undefined;
}

function customPropertyValueToUserId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    cVal.propertyType === CustomPropertyTypesEnum.REMBERG_CONTACT_SELECT
    ? cVal.value[0]
    : undefined;
}

function customPropertyValueToArrayOfStrings(cVal: unknown | undefined): string[] | undefined {
  return isCustomProperty(cVal) && cVal.propertyType === CustomPropertyTypesEnum.SELECT
    ? cVal.value
    : undefined;
}

function customPropertyValueToAssetId(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    cVal.propertyType === CustomPropertyTypesEnum.REMBERG_ASSET_SELECT
    ? cVal.value[0]
    : undefined;
}

function customPropertyValueToString(cVal: unknown | undefined): string | undefined {
  return isCustomProperty(cVal) &&
    (typeof cVal.value === 'string' || typeof cVal.value === 'number')
    ? `${cVal.value}`
    : isCustomProperty(cVal) && Array.isArray(cVal.value)
      ? cVal.value[0]
      : undefined;
}
