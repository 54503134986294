export enum FeatureFlagEnum {
  ASSETS = 'assets',
  ASSETS_STATUS = 'assetsStatus',
  ASSETS_HIERARCHY = 'assetsHierarchy',
  ASSETS_ORGANIZATIONS_AND_CONTACTS = 'assetsOrganizationsAndContacts',
  ASSETS_WORKORDERS_ADDRESS = 'assetsWorkOrdersAddress',
  ASSETS_ENABLE_CUSTOMIZATION = 'assetsEnableCustomization',

  QRCODES = 'qrcodes',

  CASES = 'cases',
  CASES_CATEGORY = 'casesCategory',
  CASES_ORGANIZATION = 'casesOrganization',
  CASES_REQUIRE_OEM_FEEDBACK = 'casesRequireOEMFeedback',

  WORKORDERS = 'workOrders',
  WORKORDERS_ORGANIZATIONS_AND_CONTACTS = 'workOrdersOrganizationsAndContacts',
  WORKORDERS_MAINTENANCEPLANS = 'maintenancePlans',
  WORKORDERS_SCHEDULING = 'scheduling',

  APPOINTMENTS_TEMPORARY = 'appointmentsTemporary',
  WORK_ORDERS_TEMPORARY = 'workOrdersTemporary',
  WORK_ORDERS_HIERARCHY = 'workOrdersHierarchy',
  MAINTENANCE_PLANS_TEMPORARY = 'maintenancePlansTemporary',

  AI_CASES = 'aiCases',
  AI_FORMS_SUMMARY = 'aiFormsSummary',
  AI_USE_EXPERIMENTAL_MODELS = 'aiUseExperimentalModels',
  AI_COPILOT = 'aiCopilot',
  AI_ADD_FORMS_TO_KNOWLEDGE_BASE_TEMPORARY = 'aiAddFormsToKnowledgeBaseTemporary',
  AI_USE_NEW_PROMPT_TEMPORARY = 'aiUseNewPromptTemporary',
  AI_TICKET_INGESTION = 'aiTicketIngestion',

  // TODO Casap Remove ASSETS_TEMPORARY and ASSET_TYPES_TEMPORARY with a migration when the minimum mobile version is > 2.62.0
  // This is a placeholder as this feature flag is hardcoded to true for mobile app compatibility
  ASSETS_TEMPORARY = 'assetsTemporary',
  ASSET_TYPES_TEMPORARY = 'assetTypesTemporary',

  TAGS_TEMPORARY = 'tagsTemporary',

  CASES_SUMMARY_TEMPORARY = 'casesSummaryTemporary',
  AI_ENFORCE_FEEDBACK_TEMPORARY = 'aiEnforceFeedbackTemporary',
  TICKETS_TEMPORARY = 'ticketsTemporary',
  TICKETS_FULL_TEXT_SEARCH_TEMPORARY = 'ticketsFullTextSearchTemporary',
  AI_CUSTOM_USER_INSTRUCTIONS_TEMPORARY = 'aiCustomUserInstructionsTemporary',
  AI_REWRITE_QUERY_TEMPORARY = 'aiRewriteQueryTemporary',

  TICKETS_PDF_EXPORT = 'ticketsPdfExport',

  TASKS = 'tasks',
  API = 'api',
  IMPORT = 'import',
  EXPORT = 'export',
  FORMS = 'forms',
  FILES = 'files',
  FILES_DOWNLOAD = 'filesDownload',
  MOBILEAPP = 'mobileApp',
  POWERED_BY_REMBERG = 'poweredByRemberg',
  PARTS = 'parts',
  GLOBAL_MAP = 'globalMap',
  DASHBOARDS = 'dashboards',

  PORTAL = 'portal',
}
